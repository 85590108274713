export const CREATE_LIVESTREAM = gql`
  mutation CreateLivestream($input: CreateLivestreamInput!) {
    createLivestream(input: $input) {
      id
      name
      logo
      description
      shortDescription
      embedCode
      sourceUrl
      livestreamComAccountId
      livestreamComEventId
      pbsStationManagerLivestreamFeedCid
      pbsTvssFeedCid
    }
  }
`

export const DELETE_LIVESTREAM = gql`
  mutation DeleteLivestream($id: String!) {
    deleteLivestream(id: $id) {
      id
      name
      logo
      description
      shortDescription
      embedCode
      sourceUrl
      livestreamComAccountId
      livestreamComEventId
      pbsStationManagerLivestreamFeedCid
      pbsTvssFeedCid
    }
  }
`

export const GET_STATION = gql`
  query GetStation($id: String!) {
    station(id: $id) {
      id
      name
      about
      addressCity
      addressState
      addressStreet
      addressZip
      email
      phone
      fax
      colorPrimary
      colorSecondary
      colorBackground
      images
      users {
        name
        id
        roles
        email
        stationId
        active
      }
      urls
    }
  }
`

export const GET_STATION_LIVESTREAMS = gql`
  query GetStationLivestreams($stationId: UUID!) {
    stationLivestreams(stationId: $stationId) {
      id
      name
      logo
      description
      shortDescription
      embedCode
      sourceUrl
      livestreamComAccountId
      livestreamComEventId
      pbsStationManagerLivestreamFeedCid
      pbsTvssFeedCid
      weight
    }
  }
`

export const UPDATE_LIVESTREAM = gql`
  mutation UpdateLivestream($id: String!, $input: UpdateLivestreamInput!) {
    updateLivestream(id: $id, input: $input) {
      id
      name
    }
  }
`

export const UPDATE_STATION = gql`
  mutation UpdateStation($id: String!, $input: UpdateStationInput!) {
    updateStation(id: $id, input: $input) {
      id
      about
      addressCity
      addressState
      addressStreet
      addressZip
      addressCountry
      email
      phone
      fax
      colorPrimary
      colorSecondary
      colorBackground
      images
      users {
        name
        id
        roles
        email
        stationId
        active
      }
      urls
    }
  }
`
