import { useEffect, useState } from 'react'

import { ImageUploadUrl } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import CardSection from 'src/components/Custom/Card/CardSection'
import ColorSelection from 'src/components/Custom/ColorSelection/ColorSelection'
import DragAndDropImageArea from 'src/components/Custom/DragAndDropImageArea/DragAndDropImageArea'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import TooltipText from 'src/components/Custom/TooltipText/TooltipText'
import {
  CREATE_IMAGE_UPLOAD_URL_MUTATION,
  ImageProfile,
  transformImage,
  uploadImageToCloudflare,
} from 'src/lib/images'

import { StationInputType, UrlsType } from '../../../common/types'

export interface BrandingData {
  colors: { label?: string; color: string; id: string }[]
  images: UrlsType[]
}

interface BrandingProps {
  data: BrandingData
  onChange: (data: {
    colors?: { label?: string; color: string; id: string }[]
    images?: UrlsType[]
  }) => void
  updateStation: (inputData: StationInputType) => Promise<void>
}

const Branding = ({ data, onChange, updateStation }: BrandingProps) => {
  const [primaryLogo, setPrimaryLogo] = useState<UrlsType | null>()
  const [secondaryLogo, setSecondaryLogo] = useState<UrlsType | null>()
  const [passportLogo, setPassportLogo] = useState<UrlsType | null>()
  const [colorLogo, setColorLogo] = useState<UrlsType | null>()
  const [whiteLogo, setWhiteLogo] = useState<UrlsType | null>()

  const findProfile = (
    array: { profile: string; url: string }[],
    profileValue: string
  ) => {
    return array?.find((obj) => obj.profile === profileValue) ?? null
  }

  useEffect(() => {
    setPrimaryLogo(findProfile(data.images, ImageProfile.STATION_LOGO_PRIMARY))
    setSecondaryLogo(
      findProfile(data.images, ImageProfile.STATION_LOGO_SECONDARY)
    )
    setPassportLogo(
      findProfile(data.images, ImageProfile.STATION_PASSPORT_LOGO)
    )
    setColorLogo(findProfile(data.images, ImageProfile.STATION_COLOR_LOGO))
    setWhiteLogo(findProfile(data.images, ImageProfile.STATION_WHITE_LOGO))
  }, [data.images])

  const [createImageUploadUrl] = useMutation<{
    createImageUploadUrl: ImageUploadUrl
  }>(CREATE_IMAGE_UPLOAD_URL_MUTATION)

  const handleImageUpload = async (files: File[], profileToUpdate: string) => {
    const updatedImages = [...data.images]

    const uploadImage = async () => {
      const mutationResult = await createImageUploadUrl()
      const { uploadUrl } = mutationResult.data.createImageUploadUrl
      const newImageUrl = await uploadImageToCloudflare(uploadUrl, files[0])
      const imageIndex = updatedImages.findIndex(
        (image) => image.profile === profileToUpdate
      )

      if (imageIndex !== -1) {
        updatedImages[imageIndex] = {
          profile: profileToUpdate,
          url: newImageUrl,
        }
      } else {
        updatedImages.push({ profile: profileToUpdate, url: newImageUrl })
      }

      updateStation({ images: updatedImages }).then(() => {
        onChange({ images: updatedImages })
      })
    }

    await toast.promise(uploadImage(), {
      loading: 'Uploading logo...',
      success: 'Logo uploaded successfully',
      error: 'Error uploading logo',
    })
  }

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center">
        <ColorSelection
          colors={data.colors}
          onColorChange={(colors) => {
            onChange({ colors: colors })

            const colorPrimary = colors.find((item) => item.id === 'primary')
            const colorSecondary = colors.find(
              (item) => item.id === 'secondary'
            )
            const colorBackground = colors.find(
              (item) => item.id === 'background'
            )
            updateStation({
              colorPrimary: colorPrimary.color,
              colorSecondary: colorSecondary.color,
              colorBackground: colorBackground.color,
            })
          }}
        />
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection
          title={
            <HeadingLevel level={2} levelStyle={4}>
              Logos
            </HeadingLevel>
          }
        >
          <div className="flex flex-col">
            <div className="mb-2 flex flex-col">
              <TooltipText
                text={
                  <HeadingLevel level={3} levelStyle={5}>
                    Primary
                  </HeadingLevel>
                }
              >
                The primary logo is used on the admin CMS website. It is the
                colored/dark version of your logo to be visible on a white
                background.
              </TooltipText>
              <div className="flex flex-col lg:flex-row">
                <div className="mb-2 w-full lg:w-1/2">
                  <div className="mt-6">
                    {primaryLogo ? (
                      <img
                        className="max-h-[100px] max-w-full object-contain"
                        src={transformImage({
                          imageUrl: primaryLogo?.url,
                          options: { width: 100 },
                        })}
                        alt="Primary logo"
                      />
                    ) : (
                      <em>Not set</em>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <DragAndDropImageArea
                    id="primaryLogo"
                    onUpload={(files) => {
                      handleImageUpload(
                        files as File[],
                        ImageProfile.STATION_LOGO_PRIMARY
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-col">
              <TooltipText
                text={
                  <HeadingLevel level={3} levelStyle={5}>
                    Secondary
                  </HeadingLevel>
                }
              >
                The secondary logo is a stacked variation of your logo (or other
                orientation). It is optional and can be used to optimize the
                channel icon. A white logo is preferred.
              </TooltipText>
              <div className="flex flex-col lg:flex-row">
                <div className="mb-2 w-full lg:w-1/2">
                  <div className="mt-6">
                    {secondaryLogo ? (
                      <img
                        className="max-h-[100px] max-w-full object-contain"
                        src={transformImage({
                          imageUrl: secondaryLogo?.url,
                          options: { width: 100 },
                        })}
                        alt="secondary logo"
                      />
                    ) : (
                      <em>Not set</em>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <DragAndDropImageArea
                    id="secondaryLogo"
                    onUpload={(files) =>
                      handleImageUpload(
                        files as File[],
                        ImageProfile.STATION_LOGO_SECONDARY
                      )
                    }
                  />
                </div>
              </div>
            </div>{' '}
            <div className="mb-2 flex flex-col">
              <TooltipText
                text={
                  <HeadingLevel level={3} levelStyle={5}>
                    Passport
                  </HeadingLevel>
                }
              >
                The Passport logo is used where Passport benefits are displayed
                or to indicate Passport content.
              </TooltipText>
              <div className="flex flex-col lg:flex-row">
                <div className="mb-2 w-full lg:w-1/2">
                  <div className="mt-6">
                    {passportLogo ? (
                      <img
                        className="max-h-[100px] max-w-full object-contain"
                        src={transformImage({
                          imageUrl: passportLogo?.url,
                          options: { width: 100 },
                        })}
                        alt="Passport logo"
                      />
                    ) : (
                      <em>Not set</em>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <DragAndDropImageArea
                    id="passportLogo"
                    onUpload={(files) =>
                      handleImageUpload(
                        files as File[],
                        ImageProfile.STATION_PASSPORT_LOGO
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-col">
              <TooltipText
                text={
                  <HeadingLevel level={3} levelStyle={5}>
                    Color Logo
                  </HeadingLevel>
                }
              >
                The color logo is not currently used.
              </TooltipText>
              <div className="flex flex-col lg:flex-row">
                <div className="mb-2 w-full lg:w-1/2">
                  <div className="mt-6">
                    {colorLogo ? (
                      <img
                        className="max-h-[100px] max-w-full object-contain"
                        src={transformImage({
                          imageUrl: colorLogo?.url,
                          options: { width: 100 },
                        })}
                        alt="color logo"
                      />
                    ) : (
                      <em>Not set</em>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <DragAndDropImageArea
                    id="colorLogo"
                    onUpload={(files) =>
                      handleImageUpload(
                        files as File[],
                        ImageProfile.STATION_COLOR_LOGO
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-col">
              <TooltipText
                text={
                  <HeadingLevel level={3} levelStyle={5}>
                    White Logo
                  </HeadingLevel>
                }
              >
                <p className="mb-1 font-medium text-red-600">
                  White logo aspect ratio must be 5.29 (recommended size:
                  720x136px).
                </p>
                <p>
                  The white logo is the most seen version of your logo, used on
                  the loading screen, home screen, and menu of the app. It can
                  also be used as the channel icon. It must be white to contrast
                  against the background color.
                </p>
              </TooltipText>
              <div className="flex flex-col lg:flex-row">
                <div className="mb-2 w-full lg:w-1/2">
                  <div className="mt-6">
                    {whiteLogo ? (
                      <img
                        className="max-h-[100px] max-w-full object-contain"
                        src={transformImage({
                          imageUrl: whiteLogo?.url,
                          options: { width: 360, background: '#000000' },
                        })}
                        alt="white logo"
                      />
                    ) : (
                      <em>Not set</em>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <DragAndDropImageArea
                    previewClassName="h-[68px] w-[360px] bg-black object-fill mx-auto"
                    id="whiteLogo"
                    onUpload={(files) =>
                      handleImageUpload(
                        files as File[],
                        ImageProfile.STATION_WHITE_LOGO
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </CardSection>
      </div>
    </div>
  )
}

export default Branding
