import React from 'react'

export type HeadingLevelProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6
  levelStyle?: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
  children: React.ReactNode
}

/**
 * Renders a heading element with a specified level, class name, and children.
 */
const HeadingLevel: React.FC<HeadingLevelProps> = ({
  level,
  levelStyle,
  className,
  children,
}) => {
  const headingSizes = [
    'text-2xl',
    'text-xl',
    'text-lg',
    'text-base',
    'text-sm',
    'text-xs',
  ]
  const headingClasses = ['font-medium', 'gray-900', className]
    .filter(Boolean)
    .join(' ')

  const styleLevel = levelStyle || level
  const styleIndex = styleLevel - 1
  const style = headingSizes[styleIndex]

  return React.createElement(
    `h${level}`,
    { className: `${style} ${headingClasses}` },
    children
  )
}

export default HeadingLevel
