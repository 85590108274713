import { IoMdHelpCircleOutline } from 'react-icons/io'

export interface TooltipTextProps {
  children: React.ReactNode
  position?: 'bottom' | 'left' | 'right' | 'top'
  text: React.ReactNode
}

const TooltipText: React.FC<TooltipTextProps> = ({
  children,
  position = 'right',
  text,
}) => {
  return (
    <div className="z-50 flex">
      {text}
      <div className="group relative ml-0.5 flex items-center">
        <div className="cursor-help">
          <IoMdHelpCircleOutline className="size-5 text-gray-500" />
        </div>
        <div
          className={`absolute ${
            position == 'bottom'
              ? 'left-1/2 top-full -translate-x-1/2'
              : position == 'left'
                ? 'right-0 mr-6'
                : position == 'top'
                  ? 'bottom-full left-1/2 -translate-x-1/2'
                  : 'left-0 ml-6' // right -- default
          } z-50 hidden w-max max-w-80 rounded-lg bg-gray-900 px-3 py-2 text-sm text-white shadow-sm transition duration-150 ease-in-out group-hover:block`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default TooltipText
