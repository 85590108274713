import { useEffect, useState } from 'react'

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { IoHandLeftOutline } from 'react-icons/io5'
import { ImageUploadUrl } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { LivestreamInputType } from 'src/common/types'
import Button from 'src/components/Custom/Button/Button'
import ConfigureLivestreamModal from 'src/components/Custom/Modal/ConfigureLivestreamModal'
import PrimaryModal from 'src/components/Custom/Modal/PrimaryModal'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import {
  CREATE_IMAGE_UPLOAD_URL_MUTATION,
  uploadImageToCloudflare,
} from 'src/lib/images'

import {
  livestreamComAccountIdFieldDescription,
  livestreamComEventIdFieldDescription,
  embedCodeFieldDescription,
  pbsStationManagerLivestreamFeedCidFieldDescription,
  sourceUrlFieldDescription,
} from './constants'
import LiveItem from './LiveItem'

const sourceTabs = [
  {
    id: 0,
    label: 'Embed code',
    name: 'embedCode',
  },
  {
    id: 1,
    label: 'Stream URL',
    name: 'sourceUrl',
  },
  {
    id: 2,
    label: 'PBS Station Manager',
    name: 'pbsStationManager',
  },
  {
    id: 3,
    label: 'Livestream.com',
    name: 'livestreamCom',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export interface Livestreams {
  channels: Array<Livestream>
}

export interface Livestream {
  id: string
  label: string
  name: string
  logo: string
  stationDescription: string
  shortStationDescription: string
  pbsTvssFeedCid: string
  source: {
    embedCode: string
    sourceUrl: string
    pbsStationManager: string
    livestreamCom: {
      livestreamComAccountId: string
      livestreamComEventId: string
    }
  }
  weight: number
}

export interface LiveProps {
  data: Livestreams
  onChange: (data: Livestreams) => void
  updateLivestream: (
    id: string,
    inputData: LivestreamInputType,
    showAlerts?: boolean
  ) => Promise<void>
  createLivestream: (inputData: LivestreamInputType) => Promise<void>
  deleteLivestream: (id: string) => Promise<void>
  stationId: string
  loadingCreateLivestream: boolean
  loadingUpdateLivestream: boolean
}

const Live = ({
  data,
  onChange,
  updateLivestream,
  createLivestream,
  deleteLivestream,
  stationId,
  loadingCreateLivestream,
  loadingUpdateLivestream,
}: LiveProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = async (event) => {
    const { active, over } = event
    const activeIndex = active.data.current.sortable.index
    const overIndex = over?.data.current.sortable.index

    if (activeIndex === overIndex) return

    const weights = data.channels.map((channel) => ({
      id: channel.id,
      weight: channel.weight,
    }))
    const updatedWeights = arrayMove(weights, activeIndex, overIndex).map(
      (item, index) => ({ ...item, weight: index })
    )

    toast.promise(
      Promise.all(
        updatedWeights.map(async (item) => {
          await updateLivestream(
            item.id,
            {
              weight: item.weight,
            },
            false
          )
        })
      ),
      {
        loading: 'Saving order...',
        success: 'Order saved',
        error: 'Something went wrong',
      }
    )
  }

  const [channelDeleteId, setChannelDeleteId] = useState(null)

  const [activeTabs, setActiveTabs] = useState(() => {
    const initialTabs = {}
    data.channels.forEach((item) => {
      initialTabs[item.id] = 0
    })
    return initialTabs
  })

  const [showModal, setShowModal] = useState(false)
  const [showPrimaryModal, setShowPrimaryModal] = useState(false)

  const updateCurrentChannel = (value: string | number, key: string, item) => {
    const newData = { ...data }
    const currentChannelData = item

    if (key === 'livestreamComAccountId' || key === 'livestreamComEventId') {
      currentChannelData.source.livestreamCom[key] = value
    } else if (
      key === 'embedCode' ||
      key === 'sourceUrl' ||
      key === 'pbsStationManager'
    ) {
      currentChannelData.source[key] = value
    } else {
      currentChannelData[key] = value
    }

    onChange(newData)
  }

  const deleteCurrentChannel = () => {
    deleteLivestream(channelDeleteId).then(() => {
      setChannelDeleteId(null)
    })
  }

  const addChannel = () => {
    if (data.channels.length < 5) {
      setShowModal(true)
    }
  }

  const [createImageUploadUrl] = useMutation<{
    createImageUploadUrl: ImageUploadUrl
  }>(CREATE_IMAGE_UPLOAD_URL_MUTATION)

  const uploadImage = async (files: File[]) => {
    const mutationResult = await createImageUploadUrl()
    const { uploadUrl } = mutationResult.data.createImageUploadUrl
    return uploadImageToCloudflare(uploadUrl, files[0])
  }

  const handleImageUpload = async (files: File[], item) => {
    await uploadImage(files).then((newImageUrl) => {
      updateLivestream(item.id, {
        logo: newImageUrl,
      }).then(() => {
        const _data = { ...data }
        item.logo = newImageUrl
        onChange(_data)
      })
    })
  }

  const nonEmptyStringRule = {
    validate: {
      text: (value: string) => {
        if (value.trim() === '') {
          return 'Please enter a non-empty string.'
        }

        return true
      },
    },
  }

  useEffect(() => {
    setActiveTabs((currentTabs) => {
      const updatedTabs = { ...currentTabs }
      data.channels.forEach((item) => {
        if (updatedTabs[item.id] === undefined) {
          updatedTabs[item.id] = 0
        }
      })
      return updatedTabs
    })
  }, [data.channels])

  const renderLivestreamSourceFields = (item: Livestream) => {
    const { source } = item
    const activeTab =
      activeTabs[item.id] !== undefined ? activeTabs[item.id] : 0
    const selectedTabName = sourceTabs[activeTab]?.name
    const selectedData = source[selectedTabName]

    return (
      <div key={selectedTabName} className="mt-4 w-full space-y-4">
        {selectedTabName === 'livestreamCom' ? (
          <div className="w-full">
            <span className="font-medium text-gray-700">
              Livestream.com Account ID
            </span>
            <UpdatableText
              value={selectedData.livestreamComAccountId}
              onChange={(v) =>
                updateCurrentChannel(v, 'livestreamComAccountId', item)
              }
              loadingData={loadingUpdateLivestream}
              onSave={() =>
                updateLivestream(item.id, {
                  livestreamComAccountId:
                    item.source.livestreamCom.livestreamComAccountId,
                })
              }
              {...(selectedTabName === 'livestreamCom' &&
              !item.source.embedCode &&
              !item.source.pbsStationManager
                ? { validationRules: nonEmptyStringRule }
                : {})}
            />
            {livestreamComAccountIdFieldDescription}

            <span className="font-medium text-gray-700">
              Livestream.com Event ID
            </span>
            <UpdatableText
              value={selectedData.livestreamComEventId}
              onChange={(v) =>
                updateCurrentChannel(v, 'livestreamComEventId', item)
              }
              loadingData={loadingUpdateLivestream}
              onSave={() =>
                updateLivestream(item.id, {
                  livestreamComEventId:
                    item.source.livestreamCom.livestreamComEventId,
                })
              }
              {...(selectedTabName === 'livestreamCom' &&
              !item.source.embedCode &&
              !item.source.pbsStationManager
                ? { validationRules: nonEmptyStringRule }
                : {})}
            />
            {livestreamComEventIdFieldDescription}
          </div>
        ) : (
          <div className="w-full">
            <span className="font-medium text-gray-700">
              {selectedTabName == 'embedCode'
                ? 'Stream Embed Code'
                : selectedTabName == 'sourceUrl'
                  ? 'Stream URL'
                  : 'Station Manager Livestream CID'}
            </span>
            <UpdatableText
              value={selectedData}
              onChange={(v) => updateCurrentChannel(v, selectedTabName, item)}
              loadingData={loadingUpdateLivestream}
              onSave={() => {
                const updatePayload = {
                  ...(selectedTabName === 'embedCode' && {
                    embedCode: selectedData,
                  }),
                  ...(selectedTabName === 'sourceUrl' && {
                    sourceUrl: selectedData,
                  }),
                  ...(selectedTabName === 'pbsStationManager' && {
                    pbsStationManagerLivestreamFeedCid: selectedData,
                  }),
                }
                return updateLivestream(item.id, updatePayload)
              }}
              {...((selectedTabName === 'embedCode' &&
                !item.source.sourceUrl &&
                !item.source.pbsStationManager &&
                !item.source.livestreamCom.livestreamComAccountId) ||
              (selectedTabName === 'sourceUrl' &&
                !item.source.embedCode &&
                !item.source.pbsStationManager &&
                !item.source.livestreamCom.livestreamComAccountId) ||
              (selectedTabName === 'pbsStationManager' &&
                !item.source.embedCode &&
                !item.source.sourceUrl &&
                !item.source.livestreamCom.livestreamComAccountId)
                ? { validationRules: nonEmptyStringRule }
                : {})}
            />
            {selectedTabName == 'embedCode'
              ? embedCodeFieldDescription
              : selectedTabName == 'sourceUrl'
                ? sourceUrlFieldDescription
                : pbsStationManagerLivestreamFeedCidFieldDescription}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="w-full space-y-2">
      <div className="my-4 flex items-center justify-end space-x-2">
        <div className="flex flex-1 flex-row items-center">
          <IoHandLeftOutline className="mr-2" />
          <p className="text-sm font-normal text-gray-900">
            Drag and drop to reorder.
          </p>
        </div>
        {data.channels.length < 5 && (
          <Button title="Add another livestream" onClick={addChannel} />
        )}
      </div>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={data.channels}
          strategy={verticalListSortingStrategy}
        >
          {data.channels.length > 0 &&
            data.channels
              .sort((a, b) => (a.weight > b.weight ? 1 : -1))
              .map((item) => {
                return (
                  <LiveItem
                    key={item.id}
                    item={item}
                    updateCurrentChannel={updateCurrentChannel}
                    isLoading={loadingUpdateLivestream}
                    updateLivestream={updateLivestream}
                    handleImageUpload={handleImageUpload}
                    setActiveTabs={setActiveTabs}
                    renderLivestreamSourceFields={renderLivestreamSourceFields}
                    onDelete={() => {
                      setShowPrimaryModal(true)
                      setChannelDeleteId(item.id)
                    }}
                  />
                )
              })}
        </SortableContext>
      </DndContext>
      {showModal && (
        <ConfigureLivestreamModal
          showModal={showModal}
          handleModalClose={() => setShowModal(false)}
          sourceTabs={sourceTabs}
          classNames={classNames}
          onSave={createLivestream}
          uploadImage={uploadImage}
          stationId={stationId}
          loadingCreateLivestream={loadingCreateLivestream}
        />
      )}

      <PrimaryModal
        modalText={'Are you sure you wish to remove this channel?'}
        buttonList={[
          {
            label: 'Remove',
            id: 'remove',
            type: 'primary',
            onClick: () => {
              deleteCurrentChannel()
              setShowPrimaryModal(false)
            },
          },
          {
            label: 'Cancel',
            id: 'cancel',
            type: 'secondary',
            onClick: () => setShowPrimaryModal(false),
          },
        ]}
        showModal={showPrimaryModal}
        setShowModal={setShowPrimaryModal}
      />
    </div>
  )
}

export default Live
