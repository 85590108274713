/**
 * Enums for the web side based on api side enums.
 */

// Only types can be imported from Prisma client!
import type {
  AvailabilityType as AvailabilityTypePrisma,
  EndUserCohort as EndUserCohortPrisma,
  PlatformType as PlatformTypePrisma,
  PosterSize as PosterSizePrisma,
  UserRole as UserRolePrisma,
  EpisodesSortOrder as EpisodesSortOrderPrisma,
  ContentRating as ContentRatingPrisma,
  ShowType as ShowTypePrisma,
  VideoType as VideoTypePrisma,
  ContentDescriptor as ContentDescriptorPrisma,
} from '@prisma/client'

import { PassportLevel } from 'src/lib/show'

export const EndUserCohort: { [k in EndUserCohortPrisma]: k } = {
  MEMBER: 'MEMBER',
  PROSPECT: 'PROSPECT',
} as const
export type EndUserCohort = EndUserCohortPrisma

export const PlatformType: { [k in PlatformTypePrisma]: k } = {
  MOBILE: 'MOBILE',
  OTT: 'OTT',
  WEB: 'WEB',
} as const
export type PlatformType = PlatformTypePrisma

export const PosterSize: { [k in PosterSizePrisma]: k } = {
  PORTRAIT_MEDIUM: 'PORTRAIT_MEDIUM',
  PORTRAIT_SMALL: 'PORTRAIT_SMALL',
  LANDSCAPE_MEDIUM: 'LANDSCAPE_MEDIUM',
  LANDSCAPE_SMALL: 'LANDSCAPE_SMALL',
} as const
export type PosterSize = PosterSizePrisma

export const UserRole: { [k in UserRolePrisma]: k } = {
  ADMIN: 'ADMIN',
  GLOBAL_GM: 'GLOBAL_GM',
  GLOBAL_MARKETER: 'GLOBAL_MARKETER',
  GLOBAL_PROGRAMMER: 'GLOBAL_PROGRAMMER',
  STATION_GM: 'STATION_GM',
  STATION_MARKETER: 'STATION_MARKETER',
  STATION_PROGRAMMER: 'STATION_PROGRAMMER',
} as const
export type UserRole = UserRolePrisma

export const userRoleMap = {
  [UserRole.ADMIN]: 'Administrator',
  [UserRole.GLOBAL_GM]: 'Global GM',
  [UserRole.GLOBAL_MARKETER]: 'Global Marketer',
  [UserRole.GLOBAL_PROGRAMMER]: 'Global Programmer',
  [UserRole.STATION_GM]: 'Station GM',
  [UserRole.STATION_MARKETER]: 'Station Marketer',
  [UserRole.STATION_PROGRAMMER]: 'Station Programmer',
}

export const endUserCohortMap = {
  [EndUserCohort.MEMBER]: 'Member',
  [EndUserCohort.PROSPECT]: 'Prospect',
  default: 'All Users',
}

export const passportLevelMap = {
  [PassportLevel.ALL]: 'All Passport',
  [PassportLevel.NONE]: 'No Passport',
  [PassportLevel.SOME]: 'Some Passport',
}

export const platformTypeMap = {
  [PlatformType.MOBILE]: 'Mobile',
  [PlatformType.OTT]: 'OTT',
  [PlatformType.WEB]: 'Web',
  default: 'All Platforms',
}

export const posterSizeMap = {
  [PosterSize.PORTRAIT_MEDIUM]: 'Portrait (Medium)',
  [PosterSize.PORTRAIT_SMALL]: 'Portrait (Small)',
  [PosterSize.LANDSCAPE_MEDIUM]: 'Landscape (Medium)',
  [PosterSize.LANDSCAPE_SMALL]: 'Landscape (Small)',
}

export const posterSizeByString = {
  'Portrait (Medium)': PosterSize.PORTRAIT_MEDIUM,
  'Portrait (Small)': PosterSize.PORTRAIT_SMALL,
  'Landscape (Medium)': PosterSize.LANDSCAPE_MEDIUM,
  'Landscape (Small)': PosterSize.LANDSCAPE_SMALL,
}

export const endUserCohortByString = {
  Member: EndUserCohort.MEMBER,
  Prospect: EndUserCohort.PROSPECT,
  All: '',
}

export const ShowType: { [k in ShowTypePrisma]: k } = {
  EPISODIC_SERIES: 'EPISODIC_SERIES',
  MOVIE: 'MOVIE',
  NEWS: 'NEWS',
  NON_EPISODIC_SERIES: 'NON_EPISODIC_SERIES',
} as const
export type ShowType = ShowTypePrisma

export const VideoType: { [k in VideoTypePrisma]: k } = {
  CLIP: 'CLIP',
  EPISODE: 'EPISODE',
  PREVIEW: 'PREVIEW',
  SPECIAL: 'SPECIAL',
} as const
export type VideoType = VideoTypePrisma

export const showTypeMap = {
  EPISODIC_SERIES: 'Episodic series',
  MOVIE: 'Movie',
  NEWS: 'News',
  NON_EPISODIC_SERIES: 'Non episodic series',
}

export const ContentRating: { [k in ContentRatingPrisma]: k } = {
  TVY: 'TVY',
  TVY7: 'TVY7',
  TV14: 'TV14',
  TVG: 'TVG',
  TVPG: 'TVPG',
  TVMA: 'TVMA',
  NR: 'NR',
} as const
export type ContentRating = ContentRatingPrisma

export const ratingMap = {
  TVY: 'TV-Y',
  TVY7: 'TV-Y7',
  TV14: 'TV-14',
  TVG: 'TV-G',
  TVPG: 'TV-PG',
  TVMA: 'TV-MA',
  NR: 'NA',
}

export const EpisodesSortOrder: { [k in EpisodesSortOrderPrisma]: k } = {
  EARLIEST: 'EARLIEST',
  EARLIEST_FROM_LATEST_SEASON: 'EARLIEST_FROM_LATEST_SEASON',
  LATEST: 'LATEST',
} as const
export type EpisodesSortOrder = EpisodesSortOrderPrisma

export const episodesSortOrderMap = {
  [EpisodesSortOrder.EARLIEST]: 'Earliest',
  [EpisodesSortOrder.EARLIEST_FROM_LATEST_SEASON]:
    'Earliest from Latest season',
  [EpisodesSortOrder.LATEST]: 'Latest',
}

export const ContentDescriptor: { [k in ContentDescriptorPrisma]: k } = {
  D: 'D',
  FV: 'FV',
  L: 'L',
  S: 'S',
  V: 'V',
} as const
export type ContentDescriptor = ContentDescriptorPrisma

export const contentDescriptorMap = {
  D: 'D - Sexual or suggestive dialogue',
  FV: 'FV - Fantasy violence',
  L: 'L - Coarse or crude language',
  S: 'S - Sexual situations',
  V: 'V - Violence',
}

export const AvailabilityType: { [k in AvailabilityTypePrisma]: k } = {
  ALL_MEMBERS: 'ALL_MEMBERS',
  PUBLIC: 'PUBLIC',
  STATION_MEMBERS: 'STATION_MEMBERS',
} as const
export type AvailabilityType = AvailabilityTypePrisma
