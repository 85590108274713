import type {
  FindPresentingSponsors,
  FindPresentingSponsorsVariables,
} from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import PresentingSponsors from 'src/components/PresentingSponsor/PresentingSponsors'

export const QUERY: TypedDocumentNode<
  FindPresentingSponsors,
  FindPresentingSponsorsVariables
> = gql`
  query FindPresentingSponsors {
    presentingSponsors {
      id
      stationId
      text
      logo
      url
      ctaText
      startAt
      endAt
      updatedAt
      createdAt
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => {
  return (
    <div>
      <div className="rw-button-group">
        <Link
          to={routes.newPresentingSponsor()}
          title="Add Presenting Sponsor"
          className="rw-button rw-button-green"
        >
          Add Presenting Sponsor
        </Link>
      </div>
    </div>
  )
}

export const Failure = ({
  error,
}: CellFailureProps<FindPresentingSponsors>) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  presentingSponsors,
}: CellSuccessProps<
  FindPresentingSponsors,
  FindPresentingSponsorsVariables
>) => {
  return <PresentingSponsors presentingSponsors={presentingSponsors} />
}
