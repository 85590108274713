import { GetLsiMemberStations } from 'types/graphql'

import Station from './Station'

export interface StationsData {
  stations: GetLsiMemberStations['stations']
}

interface StationsProps {
  data: StationsData
}

const Stations = ({ data }: StationsProps) => {
  return (
    <div className="flex w-full flex-col">
      {data.stations.map((station) => (
        <Station key={station.id} station={station} />
      ))}
    </div>
  )
}

export default Stations
