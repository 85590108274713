import CardSection from 'src/components/Custom/Card/CardSection'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import { StationUrlProfiles } from 'src/lib/urls'

import { StationInputType, UrlsType } from '../../../common/types'

export interface LinksData {
  [StationUrlProfiles.CONTACT]: string | null
  [StationUrlProfiles.DONATE]: string | null
  [StationUrlProfiles.HELP]: string | null
  [StationUrlProfiles.PASSPORT]: string | null
  [StationUrlProfiles.PRIVACY_POLICY]: string | null
  [StationUrlProfiles.TERMS_OF_USE]: string | null
  urls: UrlsType[]
}

interface LinksProps {
  data: LinksData
  onChange: (data: LinksData) => void
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
}

const Links = ({
  data,
  onChange,
  updateStation,
  loadingUpdateStation,
}: LinksProps) => {
  const updateData = (value: string, key: string) => {
    const _data = { ...data }
    _data[key] = value
    onChange(_data)
  }

  const prepareUrls = (profileInput: string) => {
    const updatedUrls = [...data.urls]

    const urlIndex = updatedUrls.findIndex(
      (url) => url.profile === profileInput
    )

    if (urlIndex !== -1) {
      updatedUrls[urlIndex] = { profile: profileInput, url: data[profileInput] }
    } else {
      updatedUrls.push({ profile: profileInput, url: data[profileInput] })
    }

    return updatedUrls
  }

  const updatedTextProps = (profile: string) => {
    return {
      value: data[profile],
      onChange: (v: string) => updateData(v, profile),
      onSave: () => updateStation({ urls: prepareUrls(profile) }),
      validationRules: {
        required: 'Please enter a valid URL',
        validate: {
          url: (value: string) => {
            const urlPattern = /^http/
            if (!value || !urlPattern.test(value)) {
              return 'Please enter a valid URL'
            }
            return true
          },
        },
      },
      loadingData: loadingUpdateStation,
    }
  }

  return (
    <div className="w-full">
      {/** TODO: Make this dynamic according to given props without causing re-renders */}
      <CardSection title="Contact">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.CONTACT)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="General donation">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.DONATE)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Help">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.HELP)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Passport">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.PASSPORT)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Privacy policy">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.PRIVACY_POLICY)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Terms of use">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.TERMS_OF_USE)} />
      </CardSection>
    </div>
  )
}

export default Links
