import { Metadata } from '@redwoodjs/web'

import HeadingLevel from 'src/components/Custom/Heading/Heading'
import StationPresentingSponsorsCell from 'src/components/PresentingSponsor/StationPresentingSponsorsCell'
import useUserStation from 'src/lib/hooks/useUserStation'

const StationPresentingSponsorsPage = () => {
  const userStationId = useUserStation()
  return (
    <div className="mx-8 flex w-full flex-grow flex-col py-6">
      <Metadata title="Presenting Sponsors" />
      <HeadingLevel level={1}>Presenting Sponsors</HeadingLevel>

      <div className="mt-10">
        <StationPresentingSponsorsCell stationId={userStationId} />
      </div>
    </div>
  )
}

export default StationPresentingSponsorsPage
