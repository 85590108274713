import React, { Fragment, useEffect, useState } from 'react'

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { BiSolidChevronDown } from 'react-icons/bi'

interface DropdownProps {
  options: Array<{ label: string; value: string }>
  buttonText?: string
  onOptionsChange?: (option: { label: string; value: string }) => void
  value?: string
  fullWidth?: boolean
  menuListClass?: string
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Dropdown = ({
  options,
  buttonText,
  onOptionsChange,
  value,
  fullWidth = false,
  menuListClass,
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState(buttonText || 'Options')

  const handleOptionSelect = (
    option: { label: string; value: string },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault() // Prevent form submission
    setSelectedOption(option.label)
    onOptionsChange?.(option)
  }

  // Update selected option when buttonText prop changes
  useEffect(() => {
    setSelectedOption(buttonText || 'Options')
  }, [buttonText])

  return (
    <Menu
      as="div"
      className={`relative inline-block text-left ${fullWidth && 'w-full'}`}
    >
      <div>
        <MenuButton
          className={`inline-flex w-full ${
            fullWidth ? 'justify-left' : 'justify-center'
          } gap-x-2 rounded-md bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
        >
          {value || selectedOption}
          <BiSolidChevronDown
            className="h-5 w-5 text-gray-700"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={`${menuListClass} absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            fullWidth ? 'w-full' : 'w-max'
          }`}
        >
          <div className="py-1">
            {options.map((option) => (
              <MenuItem key={option.value}>
                {({ focus, close }) => (
                  <button
                    onClick={(e) => {
                      handleOptionSelect(option, e)
                      close()
                    }}
                    className={classNames(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm font-medium'
                    )}
                  >
                    {option.label}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default Dropdown
