import CardSection from 'src/components/Custom/Card/CardSection'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import { StationUrlProfiles } from 'src/lib/urls'

import { StationInputType, UrlsType } from '../../../common/types'

export interface DonationUrlsData {
  [StationUrlProfiles.DONATE_URL_ANDROID]: string | null
  [StationUrlProfiles.DONATE_URL_FIRETV]: string | null
  [StationUrlProfiles.DONATE_URL_GOOGLETV]: string | null
  [StationUrlProfiles.DONATE_URL_IOS]: string | null
  [StationUrlProfiles.DONATE_URL_LGTV]: string | null
  [StationUrlProfiles.DONATE_URL_ROKU]: string | null
  [StationUrlProfiles.DONATE_URL_SAMSUNGTV]: string | null
  [StationUrlProfiles.DONATE_URL_TVOS]: string | null
  [StationUrlProfiles.DONATE_URL_WEB]: string | null
  urls: UrlsType[]
}

interface DonationUrlsProps {
  data: DonationUrlsData
  onChange: (data: DonationUrlsData) => void
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
}

const DonationUrls = ({
  data,
  onChange,
  updateStation,
  loadingUpdateStation,
}: DonationUrlsProps) => {
  const updateData = (value: string, key: string) => {
    const _data = { ...data }
    _data[key] = value
    onChange(_data)
  }

  const prepareUrls = (profileInput: string) => {
    const updatedUrls = [...data.urls]

    const urlIndex = updatedUrls.findIndex(
      (url) => url.profile === profileInput
    )

    if (urlIndex !== -1) {
      updatedUrls[urlIndex] = { profile: profileInput, url: data[profileInput] }
    } else {
      updatedUrls.push({ profile: profileInput, url: data[profileInput] })
    }

    return updatedUrls
  }

  const updatedTextProps = (profile: string) => {
    return {
      value: data[profile],
      onChange: (v: string) => updateData(v, profile),
      onSave: () => updateStation({ urls: prepareUrls(profile) }),
      validationRules: {
        required: 'Please enter a valid URL',
        validate: {
          url: (value: string) => {
            const urlPattern = /^http/
            if (!value || !urlPattern.test(value)) {
              return 'Please enter a valid URL'
            }
            return true
          },
        },
      },
      loadingData: loadingUpdateStation,
    }
  }

  return (
    <div className="w-full">
      {/** TODO: Make this dynamic according to given props without causing re-renders */}
      <CardSection title="Android">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_ANDROID)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Apple TV">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_TVOS)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Fire TV">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_FIRETV)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Google TV">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_GOOGLETV)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="iOS">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_IOS)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="LG TV">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_LGTV)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Roku">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_ROKU)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Samsung TV">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_SAMSUNGTV)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Web">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.DONATE_URL_WEB)}
        />
      </CardSection>
    </div>
  )
}

export default DonationUrls
