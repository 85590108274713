import React from 'react'

import { GetLsiMemberStations } from 'types/graphql'

import { Metadata, useQuery } from '@redwoodjs/web'

import Card from 'src/components/Custom/Card'
import HeadingLevel from 'src/components/Custom/Heading/Heading'

import { GET_LSI_MEMBER_STATIONS } from './queries'
import Stations from './Stations/Stations'

const GlobalStationManagerPage = () => {
  const { data: stationList, loading } = useQuery<GetLsiMemberStations>(
    GET_LSI_MEMBER_STATIONS
  )

  return (
    <div className="w-full bg-gray-100 p-4">
      <Metadata title="Stations" />
      <HeadingLevel level={1}>Stations</HeadingLevel>

      <div className="mt-5 space-y-6">
        {!loading && (
          <Card hideSeparator={true}>
            <Stations data={stationList} />
          </Card>
        )}
      </div>
    </div>
  )
}

export default GlobalStationManagerPage
