import React, { useState } from 'react'

import Dropdown from '../Dropdown/Dropdown'
import HeadingLevel from '../Heading/Heading'
import Links from '../Links/Links'

export type searchResultType = {
  name: {
    common: string
  }
}
export interface SearchProps {
  header: string
  dropdownOptions: { label: string; value: string }[]
  onSearch: (query: string) => Promise<searchResultType[]>
  onSearchInputChange: (word: string) => void
  onDropdownOptionsChange: (option: string) => void
}

const Search: React.FC<SearchProps> = (prop: SearchProps) => {
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearchInputChangeInternel = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keyword = event.target.value
    setSearchKeyword(keyword)
    prop.onSearchInputChange(keyword)
  }

  const onSearchKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      // Call the onSearch prop to fetch data from the API
      await prop.onSearch(searchKeyword)
    }
  }

  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false)

  const handleToggleFilters = () => {
    setIsFiltersExpanded((prev) => !prev)
  }

  const linksData = [
    {
      label: 'Reset Filters (0)',
      id: 'reset_filters',
    },
  ]

  return (
    <div>
      <HeadingLevel level={2} levelStyle={4}>
        {prop.header}
      </HeadingLevel>
      <div className="max-w-full pt-4">
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute bottom-0 left-5 top-0 my-auto h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <input
            type="text"
            placeholder="Search"
            value={searchKeyword}
            onChange={handleSearchInputChangeInternel}
            onKeyDown={onSearchKeyDown}
            className="focus:shadow-outline w-full appearance-none rounded-md border border-gray-300 py-3 pl-12 pr-4 text-sm leading-tight text-gray-900 placeholder-gray-400"
          />
        </div>
        {prop.dropdownOptions.length > 0 && (
          <div className="flex items-center">
            <button
              type="button"
              onClick={handleToggleFilters}
              className="mr-4 text-sm text-indigo-600"
            >
              {isFiltersExpanded
                ? 'Collapse Search Filters'
                : 'Display Search Filters'}
            </button>
            <div className="flex items-center">
              <Links
                links={linksData}
                linkType="primary"
                bgColor="bg-blue-500"
                linkStyle="text-sm"
              />
            </div>
          </div>
        )}
        {isFiltersExpanded && (
          <div className="mt-4 border bg-gray-100 p-4">
            {/* Placeholder content for expanded filters */}
            Placeholder for expanded filters
          </div>
        )}
      </div>
      <div className="mt-4">
        {prop.dropdownOptions.length > 0 && (
          <Dropdown
            options={prop.dropdownOptions}
            buttonText="Sorting Options"
            onOptionsChange={(option) =>
              prop.onDropdownOptionsChange(option.label)
            }
          />
        )}
      </div>
    </div>
  )
}

export default Search
