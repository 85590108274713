import type {
  DeletePresentingSponsorMutation,
  DeletePresentingSponsorMutationVariables,
  FindPresentingSponsors,
} from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import type { TypedDocumentNode } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import { QUERY as GlobalQuery } from 'src/components/PresentingSponsor/PresentingSponsorsCell'
import { QUERY as StationQuery } from 'src/components/PresentingSponsor/StationPresentingSponsorsCell/StationPresentingSponsorsCell'
import useUserStation from 'src/lib/hooks/useUserStation'

const DELETE_PRESENTING_SPONSOR_MUTATION: TypedDocumentNode<
  DeletePresentingSponsorMutation,
  DeletePresentingSponsorMutationVariables
> = gql`
  mutation DeletePresentingSponsorMutation($id: String!) {
    deletePresentingSponsor(id: $id) {
      id
    }
  }
`

const PresentingSponsorsList = ({
  presentingSponsors,
}: FindPresentingSponsors) => {
  const userStationId = useUserStation()
  const [deletePresentingSponsor] = useMutation(
    DELETE_PRESENTING_SPONSOR_MUTATION,
    {
      onCompleted: () => {
        toast.success('Presenting Sponsor deleted')
      },
      onError: (error) => {
        toast.error(error.message)
      },
      // This refetches the query on the list page. Read more about other ways to
      // update the cache over here:
      // https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
      refetchQueries: [
        { query: GlobalQuery },
        { query: StationQuery, variables: { stationId: userStationId } },
      ],
      awaitRefetchQueries: true,
    }
  )

  const onDeleteClick = (
    id: DeletePresentingSponsorMutationVariables['id']
  ) => {
    if (
      confirm('Are you sure you want to delete Presenting Sponsor ' + id + '?')
    ) {
      deletePresentingSponsor({ variables: { id } })
    }
  }

  return (
    <div className="rw-segment rw-table-wrapper-responsive">
      <ContentListingTable
        contentList={presentingSponsors}
        tableHeading={[
          { id: 'name', label: 'Name', field: 'name' },
          {
            field: 'logo',
            id: 'logo',
            label: 'Logo',
            type: 'image',
          },
          { id: 'url', label: 'URL', field: 'url' },
          { id: 'ctaText', label: 'CTA Text', field: 'ctaText' },
          {
            id: 'startAt',
            label: 'Start At',
            field: 'startAt',
            type: 'date',
          },
          {
            id: 'endAt',
            label: 'End At',
            field: 'endAt',
            type: 'date',
          },
          {
            id: 'action',
            field: 'actions',
            label: 'Action',
          },
        ]}
        actionsList={(presentingSponsor) => (
          <nav className="rw-table-actions">
            <Link
              to={routes.editPresentingSponsor({
                id: presentingSponsor.id,
              })}
              title={'Edit presentingSponsor ' + presentingSponsor.id}
              className="rw-button rw-button-small rw-button-blue"
            >
              Edit
            </Link>
            <button
              type="button"
              title={'Delete presentingSponsor ' + presentingSponsor.id}
              className="rw-button rw-button-small rw-button-red"
              onClick={() => onDeleteClick(presentingSponsor.id)}
            >
              Delete
            </button>
          </nav>
        )}
      />
      <div className="rw-button-group">
        <Link
          to={routes.newPresentingSponsor()}
          title="Add Presenting Sponsor"
          className="rw-button rw-button-green"
        >
          Add Presenting Sponsor
        </Link>
      </div>
    </div>
  )
}

export default PresentingSponsorsList
