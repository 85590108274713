// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import MainLayout from 'src/layouts/MainLayout/MainLayout'
import { UserRole } from 'src/lib/enums'

import Loader from './components/Custom/Loader/Loader'
import { MetadataCreatorsPage, MetadataGenresPage, MetadataVibesPage } from './pages/GlobalMetaDataManagerPage/Components/MetadataListingTables'
import MissingMetadatListing from './pages/GlobalMetaDataManagerPage/Components/MissingMetadatListing'
import NewShowsListing from './pages/GlobalMetaDataManagerPage/Components/NewShowsListing'
import GlobalStationManagerPage from './pages/GlobalStationManagerPage/GlobalStationManagerPage'
import GlobalUsersPage from './pages/GlobalUsersPage/GlobalUsersPage'
import LocalStationManager from './pages/LocalStationManager/LocalStationManager'
import StationPresentingSponsorsPage from './pages/PresentingSponsor/StationPresentingSponsorsPage/StationPresentingSponsorsPage'

const customRouteParamTypes = {
  // Station call sign: exactly 4 upper case letters starting with K or W.
  stationCallSign: {
    match: /[KW]{1}[A-Z]{3}/,
  },
}

// See: https://community.redwoodjs.com/t/prerendering-whileloadingauth-and-unexpected-jsx-element-in-routes-tsx/5924
// eslint-disable-next-line
const PageLoader = () => <div className="absolute left-1/2 top-1/2"><Loader /></div>
// eslint-disable-next-line
const MainLoader = () => <MainLayout loading />

// See API documentation on `UserRole` enum for role access guidance.
const Routes = () => {
  return (
    <Router useAuth={useAuth} paramTypes={customRouteParamTypes}>
      <Route path="/" page={HomePage} whileLoadingPage={PageLoader} name="home" />

      <Set>
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      </Set>

      <Set>
        <Route path="/activate-device/{stationCallSign:stationCallSign}" page={ActivateDevicePage} name="activateDevice" />
        <Route path="/device-login/{stationCallSign:stationCallSign}" page={DeviceLoginPage} name="deviceLogin" />
      </Set>

      <PrivateSet unauthenticated="login" whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/dashboard" page={DashboardPage} name="dashboard" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.STATION_PROGRAMMER]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/carousels/lineup" page={CarouselLineupPage} name="carouselLineup" />
        <Route path="/heroes/lineup" page={HeroLineupPage} name="heroLineup" />
        <Route path="/shows/new" page={ShowNewShowPage} name="newShow" />
        <Route path="/shows/{id}/edit" page={ShowEditShowPage} name="editShow" />
        <Route path="/shows/{id}" page={ShowShowPage} name="show" />
        <Route path="/shows" page={ShowShowsPage} name="shows" />
        <Route path="/videos/new" page={VideoNewVideoPage} name="newVideo" />
        <Route path="/videos/{id}/edit" page={VideoEditVideoPage} name="editVideo" />
        <Route path="/videos/{id}" page={VideoVideoPage} name="video" />
        <Route path="/videos" page={VideoVideosPage} name="videos" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.STATION_MARKETER]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/promo-messages/new" page={PromoMessagePromoMessagesPage} name="newPromoMessage" />
        <Route path="/promo-messages/{id}/edit" page={PromoMessageEditPromoMessagePage} name="editPromoMessage" />
        <Route path="/promo-messages/{id}" page={PromoMessagePromoMessagePage} name="promoMessage" />
        <Route path="/messaging" page={MemberMessagingPage} name="messaging" />
        <Route path="/promos/new" page={PromoNewPromoPage} name="newPromo" />
        <Route path="/promos/{id}/edit" page={PromoEditPromoPage} name="editPromo" />
        <Route path="/promos/{id}" page={PromoPromoPage} name="promo" />
        <Route path="/promos" page={PromoPromosPage} name="promos" />
        <Route path="/presenting-sponsors/new" page={PresentingSponsorNewPresentingSponsorPage} name="newPresentingSponsor" />
        <Route path="/presenting-sponsors/{id}/edit" page={PresentingSponsorEditPresentingSponsorPage} name="editPresentingSponsor" />
        <Route path="/presenting-sponsors/{id}" page={PresentingSponsorPresentingSponsorPage} name="presentingSponsor" />
        <Route path="/presenting-sponsors" page={StationPresentingSponsorsPage} name="presentingSponsors" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.STATION_GM]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/station" page={LocalStationManager} name="localStationManager" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.ADMIN, UserRole.GLOBAL_PROGRAMMER]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/global/carousels/lineup/{id}/edit" page={CarouselLineupPage} name="editCarouselLineup" />
        <Route path="/global/carousel-lineups/new" page={CollectionGroupNewCollectionGroupPage} name="newCollectionGroup" />
        <Route path="/global/carousel-lineups/{id}/edit" page={CollectionGroupEditCollectionGroupPage} name="editCollectionGroup" />
        <Route path="/global/carousel-lineups/{id}" page={CollectionGroupCollectionGroupPage} name="collectionGroup" />
        <Route path="/global/carousel-lineups" page={CollectionGroupCollectionGroupsPage} name="collectionGroups" />
        <Route path="/global/carousels/new" page={CollectionNewCollectionPage} name="newCollection" />
        <Route path="/global/carousels/{id}/edit" page={CollectionEditCollectionPage} name="editCollection" />
        <Route path="/global/carousels/{id}" page={CollectionCollectionPage} name="collection" />
        <Route path="/global/carousels" page={CollectionCollectionsPage} name="collections" />
        <Route path="/global/creators" page={MetadataCreatorsPage} name="globalMetaDataManagerCreators" />
        <Route path="/global/genres/new" page={GenreVibeNewGenreVibePage} name="newGenre" />
        <Route path="/global/genres/{id}/edit" page={GenreVibeEditGenreVibePage} name="editGenre" />
        <Route path="/global/genres/{id}" page={GenreVibeGenreVibePage} name="genre" />
        <Route path="/global/genres" page={MetadataGenresPage} name="globalMetaDataManagerGenres" />
        <Route path="/global/heroes/lineup/{id}/edit" page={HeroLineupPage} name="editHeroLineup" />
        <Route path="/global/hero-lineups/new" page={HeroGroupNewHeroGroupPage} name="newHeroGroup" />
        <Route path="/global/hero-lineups/{id}/edit" page={HeroGroupEditHeroGroupPage} name="editHeroGroup" />
        <Route path="/global/hero-lineups/{id}" page={HeroGroupHeroGroupPage} name="heroGroup" />
        <Route path="/global/hero-lineups" page={HeroGroupHeroGroupsPage} name="heroGroups" />
        <Route path="/global/heroes/new" page={HeroNewHeroPage} name="newHero" />
        <Route path="/global/heroes/{id}/edit" page={HeroEditHeroPage} name="editHero" />
        <Route path="/global/heroes/{id}" page={HeroHeroPage} name="hero" />
        <Route path="/global/heroes" page={HeroHeroesPage} name="heroes" />
        <Route path="/global/shows/added" page={NewShowsListing} name="globalMetaDataManagerNewShows" />
        <Route path="/global/shows/missing-metadata" page={MissingMetadatListing} name="globalMetaDataManagerListing" />
        <Route path="/global/shows/new" page={ShowNewShowPage} name="newGlobalShow" />
        <Route path="/global/shows/{id}/edit" page={ShowEditShowPage} name="editGlobalShow" />
        <Route path="/global/shows/{id}" page={ShowShowPage} name="globalShow" />
        <Route path="/global/shows" page={ShowShowsPage} name="globalShows" />
        <Route path="/global/vibes/new" page={GenreVibeNewGenreVibePage} name="newVibe" />
        <Route path="/global/vibes/{id}/edit" page={GenreVibeEditGenreVibePage} name="editVibe" />
        <Route path="/global/vibes/{id}" page={GenreVibeGenreVibePage} name="vibe" />
        <Route path="/global/vibes" page={MetadataVibesPage} name="globalMetaDataManagerVibes" />
        <Route path="/global/videos/new" page={VideoNewVideoPage} name="newGlobalVideo" />
        <Route path="/global/videos/{id}/edit" page={VideoEditVideoPage} name="editGlobalVideo" />
        <Route path="/global/videos/{id}" page={VideoVideoPage} name="globalVideo" />
        <Route path="/global/videos" page={VideoVideosPage} name="globalVideos" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.ADMIN, UserRole.GLOBAL_GM]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/global/stations" page={GlobalStationManagerPage} name="globalStationManager" />
        <Route path="/global/stations/{id}" page={LocalStationManager} name="editLocalStation" />
        <Route path="/global/users" page={GlobalUsersPage} name="globalUsers" />
      </PrivateSet>

      <PrivateSet unauthenticated="forbidden" roles={[UserRole.ADMIN]} whileLoadingAuth={MainLoader} wrap={MainLayout}>
        <Route path="/api-users/new" page={ApiUserNewApiUserPage} name="newApiUser" />
        <Route path="/api-users/{id}/edit" page={ApiUserEditApiUserPage} name="editApiUser" />
        <Route path="/api-users/{id}" page={ApiUserApiUserPage} name="apiUser" />
        <Route path="/api-users" page={ApiUserApiUsersPage} name="apiUsers" />
        <Route path="/collection-items/new" page={CollectionItemNewCollectionItemPage} name="newCollectionItem" />
        <Route path="/collection-items/{id}/edit" page={CollectionItemEditCollectionItemPage} name="editCollectionItem" />
        <Route path="/collection-items/{id}" page={CollectionItemCollectionItemPage} name="collectionItem" />
        <Route path="/collection-items" page={CollectionItemCollectionItemsPage} name="collectionItems" />
        <Route path="/content" page={ContentLibraryPage} name="contentLibrary" />
        <Route path="/genre-vibes/new" page={GenreVibeNewGenreVibePage} name="newGenreVibe" />
        <Route path="/genre-vibes/{id}/edit" page={GenreVibeEditGenreVibePage} name="editGenreVibe" />
        <Route path="/genre-vibes/{id}" page={GenreVibeGenreVibePage} name="genreVibe" />
        <Route path="/genre-vibes" page={GenreVibeGenreVibesPage} name="genreVibes" />
        <Route path="/pbs-account-configs/new" page={PbsAccountConfigNewPbsAccountConfigPage} name="newPbsAccountConfig" />
        <Route path="/pbs-account-configs/{id}/edit" page={PbsAccountConfigEditPbsAccountConfigPage} name="editPbsAccountConfig" />
        <Route path="/pbs-account-configs/{id}" page={PbsAccountConfigPbsAccountConfigPage} name="pbsAccountConfig" />
        <Route path="/pbs-account-configs" page={PbsAccountConfigPbsAccountConfigsPage} name="pbsAccountConfigs" />
        <Route path="/pbs-shows/new" page={PbsShowNewPbsShowPage} name="newPbsShow" />
        <Route path="/pbs-shows/{id}/edit" page={PbsShowEditPbsShowPage} name="editPbsShow" />
        <Route path="/pbs-shows/{id}" page={PbsShowPbsShowPage} name="pbsShow" />
        <Route path="/pbs-shows" page={PbsShowPbsShowsPage} name="pbsShows" />
        <Route path="/pbs-videos/new" page={PbsVideoNewPbsVideoPage} name="newPbsVideo" />
        <Route path="/pbs-videos/{id}/edit" page={PbsVideoEditPbsVideoPage} name="editPbsVideo" />
        <Route path="/pbs-videos/{id}" page={PbsVideoPbsVideoPage} name="pbsVideo" />
        <Route path="/pbs-videos" page={PbsVideoPbsVideosPage} name="pbsVideos" />
        <Route path="/people/new" page={PersonNewPersonPage} name="newPerson" />
        <Route path="/people/{id}/edit" page={PersonEditPersonPage} name="editPerson" />
        <Route path="/people/{id}" page={PersonPersonPage} name="person" />
        <Route path="/people" page={PersonPeoplePage} name="people" />
        <Route path="/roles/new" page={RoleNewRolePage} name="newRole" />
        <Route path="/roles/{id}/edit" page={RoleEditRolePage} name="editRole" />
        <Route path="/roles/{id}" page={RoleRolePage} name="role" />
        <Route path="/roles" page={RoleRolesPage} name="roles" />
        <Route path="/stations/new" page={StationNewStationPage} name="newStation" />
        <Route path="/stations/{id}/edit" page={StationEditStationPage} name="editStation" />
        <Route path="/stations/{id}" page={StationStationPage} name="station" />
        <Route path="/stations" page={StationStationsPage} name="stations" />
        <Route path="/station-credential-sets/new" page={StationCredentialSetNewStationCredentialSetPage} name="newStationCredentialSet" />
        <Route path="/station-credential-sets/{id}/edit" page={StationCredentialSetEditStationCredentialSetPage} name="editStationCredentialSet" />
        <Route path="/station-credential-sets/{id}" page={StationCredentialSetStationCredentialSetPage} name="stationCredentialSet" />
        <Route path="/station-credential-sets" page={StationCredentialSetStationCredentialSetsPage} name="stationCredentialSets" />
        <Route path="/station-shows/new" page={StationShowNewStationShowPage} name="newStationShow" />
        <Route path="/station-shows/{id}/edit" page={StationShowEditStationShowPage} name="editStationShow" />
        <Route path="/station-shows/{id}" page={StationShowStationShowPage} name="stationShow" />
        <Route path="/station-shows" page={StationShowStationShowsPage} name="stationShows" />
        <Route path="/station-videos/new" page={StationVideoNewStationVideoPage} name="newStationVideo" />
        <Route path="/station-videos/{id}/edit" page={StationVideoEditStationVideoPage} name="editStationVideo" />
        <Route path="/station-videos/{id}" page={StationVideoStationVideoPage} name="stationVideo" />
        <Route path="/station-videos" page={StationVideoStationVideosPage} name="stationVideos" />
        <Route path="/users/new" page={UserNewUserPage} name="newUser" />
        <Route path="/users/{id:string}/edit" page={UserEditUserPage} name="editUser" />
        <Route path="/users/{id:string}" page={UserUserPage} name="user" />
        <Route path="/users" page={UserUsersPage} name="users" />
        <Route path="/webhooks/new" page={WebhookNewWebhookPage} name="newWebhook" />
        <Route path="/webhooks/{id}/edit" page={WebhookEditWebhookPage} name="editWebhook" />
        <Route path="/webhooks/{id}" page={WebhookWebhookPage} name="webhook" />
        <Route path="/webhooks" page={WebhookWebhooksPage} name="webhooks" />
      </PrivateSet>

      <Route notfound page={NotFoundPage} />
      <Route path="/forbidden" page={ForbiddenPage} name="forbidden" />
    </Router>
  )
}

export default Routes
