export const embedCodeFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    Livestream player embed code (web only).
  </small>
)

export const sourceUrlFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    Livestream source URL. Only unencrypted HLS (<code>m3u8</code>) and DASH (
    <code>mpd</code>) formats are supported.
  </small>
)

export const livestreamComAccountIdFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    The Livestream.com Account ID. Log in to Livesstream.com and insoect the{' '}
    {<code>__cu_id</code>} cookie to get this value.
  </small>
)

export const livestreamComEventIdFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    The Livestream.com Event ID. From the Livestream.com dashboard select the
    livestream and copy the event ID value from the URL:{' '}
    {
      <code>
        {'https://livestream.com/{accountShortName}/events/{eventId}'}
      </code>
    }
  </small>
)

export const pbsStationManagerLivestreamFeedCidFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    {
      "The ID associated with this channel's livestream data in PBS Station Manager."
    }
  </small>
)
