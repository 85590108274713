import React from 'react'

interface CardSectionProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  children: React.ReactNode
}

const CardSection: React.FC<CardSectionProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className="flex w-full flex-col pb-5 pt-5 lg:flex-row">
      <div className="mb-2 mr-6 w-full lg:w-1/3">
        <div className="font-medium text-gray-500">{title}</div>
        {subtitle && <span className="text-sm text-gray-500">{subtitle}</span>}
      </div>
      <div className="w-full lg:w-2/3">{children}</div>
    </div>
  )
}

export default CardSection
