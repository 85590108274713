export const RightArrow = ({ classStyle = '' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={`transform ${classStyle}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33337 2.4043L10.5724 7.64332C10.6245 7.69545 10.6245 7.77985 10.5724 7.83192L5.33337 13.071"
      stroke="#6C727F"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
