export const CREATE_STATION_USER = gql`
  mutation CreateStationUser(
    $input: CreateStationUserInput!
    $pageName: String!
  ) {
    createStationUser(input: $input, pageName: $pageName) {
      name
      email
      id
      stationId
    }
  }
`

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($id: String!) {
    deactivateUser(id: $id) {
      name
      email
      id
      stationId
      roles
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      name
      email
      stationId
      roles
      active
    }
  }
`

export const UPDATE_STATION_USER = gql`
  mutation UpdateStationUser(
    $id: String!
    $input: UpdateUserInput!
    $pageName: String!
  ) {
    updateStationUser(id: $id, input: $input, pageName: $pageName) {
      name
      email
      id
      stationId
      roles
    }
  }
`
