import { Fragment, useState } from 'react'

import { HexColorInput, HexColorPicker } from 'react-colorful'

import Button from '../Button/Button'
import CardSection from '../Card/CardSection'
import './ColorSelection.css'
import TooltipText from '../TooltipText/TooltipText'

interface Color {
  label?: string
  labelTooltip?: React.ReactNode
  color: string
  id: string
}
const ColorSelection = ({
  colors,
  onColorChange,
}: {
  colors: Array<Color>
  onColorChange?: (colors: Array<Color>) => void
}) => {
  const [colorList, setColorList] = useState<Array<Color>>(colors)
  const [displayColorPicker, setDisplayColorPicker] = useState(null)

  const onColorSelected = () => {
    const updatedColorList = colorList.reduce(
      (prev, curr) =>
        curr.id === displayColorPicker.id
          ? [...prev, { ...curr, color: displayColorPicker.color }]
          : [...prev, curr],
      []
    )
    setColorList(updatedColorList)
    setDisplayColorPicker(null)
    if (onColorChange) {
      onColorChange(updatedColorList)
    }
  }

  return (
    <CardSection
      title="Colors"
      subtitle="Colors configured here impact your station's activation and login web pages. For color changes within your streaming applications, contact Cascade PBS."
    >
      <div className="relative flex space-x-12">
        {colorList?.length &&
          colorList.map((colorItem, index) => (
            <Fragment key={index}>
              <button
                className="flex flex-col items-center"
                onClick={() => setDisplayColorPicker(colorItem)}
              >
                <div
                  className={`h-[48px] w-[48px] rounded-[24px] border border-black`}
                  style={{
                    backgroundColor: colorItem.color,
                  }}
                />
                {colorItem?.label && colorItem?.labelTooltip ? (
                  <TooltipText text={colorItem?.label} position="bottom">
                    {colorItem.labelTooltip}
                  </TooltipText>
                ) : colorItem?.label ? (
                  <div>{colorItem.label}</div>
                ) : (
                  <></>
                )}
              </button>
              {displayColorPicker && (
                <div className="absolute top-[125%] z-10 ml-7 rounded-lg bg-white p-4 shadow-lg">
                  <HexColorPicker
                    color={displayColorPicker.color}
                    onChange={(color) =>
                      setDisplayColorPicker({ ...displayColorPicker, color })
                    }
                  />
                  <HexColorInput
                    className="mt-2 rounded-md"
                    color={displayColorPicker.color}
                    onChange={(color) =>
                      setDisplayColorPicker({ ...displayColorPicker, color })
                    }
                    prefixed
                  />
                  <div className="flex justify-evenly pt-2">
                    <Button
                      title="OK"
                      onClick={() => onColorSelected()}
                      backgroundColor="bg-green-700"
                      customButtonClass="min-w-[80px] rounded-lg px-4 py-2 text-sm"
                    />
                    <Button
                      title="Cancel"
                      onClick={() => setDisplayColorPicker(null)}
                      backgroundColor="bg-red-600"
                      customButtonClass="min-w-[80px] rounded-lg px-4 py-2 text-sm"
                    />
                  </div>
                </div>
              )}
            </Fragment>
          ))}
      </div>
    </CardSection>
  )
}

export default ColorSelection
