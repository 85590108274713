import { useAuth } from 'src/auth'

const useUserStation = () => {
  const { currentUser } = useAuth()

  const userStationId = currentUser?.station?.id

  return userStationId
}

export default useUserStation
