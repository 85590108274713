import type {
  FindStationPresentingSponsors,
  FindStationPresentingSponsorsVariables,
} from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import PresentingSponsors from 'src/components/PresentingSponsor/PresentingSponsors'

export const QUERY: TypedDocumentNode<
  FindStationPresentingSponsors,
  FindStationPresentingSponsorsVariables
> = gql`
  query FindStationPresentingSponsors($stationId: UUID!) {
    stationPresentingSponsors(stationId: $stationId) {
      id
      stationId
      name
      text
      logo
      url
      ctaText
      startAt
      endAt
      updatedAt
      createdAt
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => {
  return (
    <div>
      <div className="rw-button-group">
        <Link
          to={routes.newPresentingSponsor()}
          title="Add Presenting Sponsor"
          className="rw-button rw-button-green"
        >
          Add Presenting Sponsor
        </Link>
      </div>
    </div>
  )
}

export const Failure = ({
  error,
}: CellFailureProps<FindStationPresentingSponsors>) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  stationPresentingSponsors,
}: CellSuccessProps<
  FindStationPresentingSponsors,
  FindStationPresentingSponsorsVariables
>) => {
  return <PresentingSponsors presentingSponsors={stationPresentingSponsors} />
}
