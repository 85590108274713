/**
 * Passport levels for shows (based on available videos).
 */
export enum PassportLevel {
  // All available videos require Passport.
  ALL = 'ALL',
  // Some available videos require Passport.
  SOME = 'SOME',
  // No available videos require Passport.
  NONE = 'NONE',
}
