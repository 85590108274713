export const COPY_LINEUP_TO_TARGET_COUNTRY = gql`
  mutation CopyLineupToTargetCountry(
    $id: String!
    $targetCountry: CountryCode!
  ) {
    copyToTargetCountry(id: $id, targetCountry: $targetCountry) {
      id
      title
      items {
        id
        showId
      }
      targetCountry
    }
  }
`

export const CREATE_HERO = gql`
  mutation CreateHero($input: CreateHeroInput!) {
    createHero(input: $input) {
      id
      title
    }
  }
`

export const CREATE_HERO_GROUP = gql`
  mutation CreateHeroGroup($input: CreateHeroGroupInput!) {
    createHeroGroup(input: $input) {
      id
    }
  }
`

export const DELETE_GENRE_VIBE = gql`
  mutation DeleteGenreVibe($id: String!) {
    deleteGenreVibe(id: $id) {
      id
    }
  }
`

export const DELETE_HERO = gql`
  mutation DeleteHero($id: String!) {
    deleteHero(id: $id) {
      id
    }
  }
`

export const GET_GLOBAL_HERO_GROUPS = gql`
  query GetGlobalHeroGroups($targetCountry: CountryCode!) {
    heroGroupsWithoutStation {
      id
      targetCountry
      draft
      autopilot
      deleted
      items {
        id
        title
        body
        startAt
        endAt
        images
        weight
        targetPlatformTypes
        targetCohorts
        show {
          id
          availableVideosMetadata
        }
        normalizedShow(country: $targetCountry) {
          genreVibes {
            name
            type
          }
          images {
            image
            profile
          }
        }
        heroMezzanineAltText
        heroMobileAltText
        weight
      }
    }
  }
`

export const GET_HERO_GROUP = gql`
  query GetHeroGroup($id: String!, $targetCountry: CountryCode) {
    heroGroup(id: $id) {
      title
      targetCountry
      description
      id
      stationId
      mobileItemCount
      webItemCount
      ottItemCount
      ottAutosort
      webAutosort
      mobileAutosort
      autopilot
      updatedAt
      items {
        id
        title
        body
        startAt
        endAt
        images
        weight
        targetPlatformTypes
        targetCohorts
        show {
          id
          availableVideosMetadata
        }
        normalizedShow(country: $targetCountry) {
          genreVibes {
            name
            type
          }
          images {
            image
            profile
          }
        }
        heroMezzanineAltText
        heroMobileAltText
        weight
      }
    }
  }
`

export const GET_HERO_GROUPS = gql`
  query GetHeroGroups(
    $targetCountry: CountryCode
    $stationId: UUID!
    $autopilot: Boolean
  ) {
    heroGroups(
      targetCountry: $targetCountry
      stationId: $stationId
      autopilot: $autopilot
    ) {
      title
      targetCountry
      description
      id
      stationId
      mobileItemCount
      webItemCount
      ottItemCount
      ottAutosort
      webAutosort
      mobileAutosort
      autopilot
      updatedAt
      items {
        id
        title
        body
        startAt
        endAt
        images
        weight
        targetPlatformTypes
        targetCohorts
        show {
          id
          availableVideosMetadata
        }
        normalizedShow(country: $targetCountry, stationId: $stationId) {
          genreVibes {
            name
            type
          }
          images {
            image
            profile
          }
        }
        heroMezzanineAltText
        heroMobileAltText
        weight
      }
    }
  }
`

export const GET_PBS_GENRES = gql`
  query GetPbsGenres {
    genreVibes(type: PBS_GENRE) {
      id
      slug
      name
      type
    }
  }
`

export const GET_STATION_HERO_CONFIG = gql`
  query GetStationHeroConfig($stationId: String!) {
    station(id: $stationId) {
      id
      borderStation
      useHeroGroupAutopilot
    }
  }
`

export const GET_VIBES = gql`
  query GetVibes {
    genreVibes(type: VIBE) {
      id
      slug
      name
      type
    }
  }
`

export const UPDATE_HERO = gql`
  mutation UpdateHero($id: String!, $input: UpdateHeroInput!) {
    updateHero(id: $id, input: $input) {
      id
      title
      body
      startAt
      endAt
      images
      weight
      targetPlatformTypes
      targetCohorts
      show {
        id
        availableVideosMetadata
      }
    }
  }
`

export const UPDATE_HERO_GROUP = gql`
  mutation UpdateHeroGroup($input: UpdateHeroGroupInput!, $id: String!) {
    updateHeroGroup(input: $input, id: $id) {
      mobileItemCount
      webItemCount
      ottItemCount
      ottAutosort
      webAutosort
      mobileAutosort
    }
  }
`

export const GET_HERO_LINEUP_PREVIEW = gql`
  query GetHeroLineupPreview(
    $stationId: UUID!
    $cohort: EndUserCohort
    $targetCountry: CountryCode
    $platformType: PlatformType
  ) {
    homeScreenData(stationId: $stationId) {
      heroes(
        cohort: $cohort
        country: $targetCountry
        platformType: $platformType
      ) {
        title
        id
        images {
          image
          profile
        }
      }
    }
  }
`
