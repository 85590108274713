import { useEffect } from 'react'

import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import Loader from 'src/components/Custom/Loader/Loader'
import Sidebar from 'src/components/Custom/Sidebar/Sidebar'
import Sentry from 'src/lib/sentry'

type MainLayoutProps = {
  children?: React.ReactNode
  loading?: boolean
}

const MainLayout = ({ children, loading = false }: MainLayoutProps) => {
  const { currentUser } = useAuth()
  useEffect(() => Sentry.setUser(currentUser), [currentUser])

  return (
    <>
      <Toaster />
      {loading ? (
        <div className="absolute left-1/2 top-1/2">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="sticky top-0 h-screen">
            <Sidebar />
          </div>
          {children}
        </div>
      )}
    </>
  )
}

export default MainLayout
