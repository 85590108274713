import Dropdown from '../Dropdown/Dropdown'

interface PaginationProps {
  sortBy?: string
  sortOptions?: { label: string; value: string }[]
  onNextPage?: () => void
  onPrevPage?: () => void
  onSortChange?: (value) => void
}

const Pagination = ({
  sortBy,
  sortOptions,
  onNextPage,
  onPrevPage,
  onSortChange,
}: PaginationProps) => {
  return (
    <nav
      className="flex-column flex flex-wrap items-center justify-start py-4"
      aria-label="Table navigation"
    >
      <ul className="flex flex-1">
        {sortBy && (
          <li>
            <Dropdown
              value={sortBy}
              options={sortOptions ?? []}
              onOptionsChange={(option) => onSortChange(option)}
              buttonText="Sort by"
            />
          </li>
        )}
      </ul>
      <ul className="inline-flex h-8 items-center justify-center -space-x-px text-sm rtl:space-x-reverse">
        <li>
          <button
            onClick={onPrevPage}
            className="dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ms-0 flex h-8 items-center justify-center rounded-s-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            Previous
          </button>
        </li>
        <li>
          <button
            onClick={onNextPage}
            className="dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
