import React, { useEffect, useState } from 'react'

import { CheckboxItemProps } from '../../../common/types'

interface CheckboxGroupProps {
  checkboxes: CheckboxItemProps[]
}

const Checkbox = ({
  id,
  title,
  checkboxContainerClass = '',
  onValueChange,
  defaultValue,
}: CheckboxItemProps) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== isChecked) {
      setIsChecked(defaultValue || false)
    }
    // We only want to call this to set up initial value of checkbox
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setIsChecked(checked)
    return onValueChange && onValueChange(checked)
  }

  return (
    <div className={`flex items-center ${checkboxContainerClass}`}>
      <input
        id={id}
        name={id}
        type="checkbox"
        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        checked={isChecked}
        onChange={handleValueChange}
      />
      <label
        htmlFor={id}
        className="ml-3 flex items-center text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
    </div>
  )
}

const CheckboxGroup = ({ checkboxes }: CheckboxGroupProps) => {
  return (
    <fieldset className="flex flex-wrap gap-4">
      {checkboxes.map((checkbox) => (
        <Checkbox key={checkbox.id} {...checkbox} />
      ))}
    </fieldset>
  )
}

export { Checkbox, CheckboxGroup }

export default Checkbox
