import { useEffect, useState } from 'react'

import { useBlocker } from '@redwoodjs/router'

const useWarnUnsavedChanges = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const blocker = useBlocker({ when: unsavedChanges })

  // Warn users of unsaved changes on navigation within app.
  if (blocker.state === 'BLOCKED') {
    if (
      window.confirm(
        'Are you sure you want to leave? You may lose unsaved changes.'
      )
    )
      blocker.confirm()
    else blocker.abort()
  }

  // Warn users of unsaved changes on reload, tab close, and browser close.
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()

      return ''
    }

    if (unsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [unsavedChanges])

  return setUnsavedChanges
}

export default useWarnUnsavedChanges
