import React from 'react'

import dayjs, { Dayjs } from 'dayjs'
import humanize from 'humanize-string'

const MAX_STRING_LENGTH = 150

export const formatEnum = (values: string | string[] | null | undefined) => {
  let output = ''

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value))
    output = humanizedValues.join(', ')
  } else if (typeof values === 'string') {
    output = humanize(values)
  }

  return output
}

export const jsonDisplay = (obj: unknown) => {
  return (
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  )
}

export const listDisplay = (values: string[] | null | undefined) => {
  let output: string | React.JSX.Element = ''

  if (Array.isArray(values)) {
    output = (
      <ol>
        {values.map((value) => (
          <li key={value}>{value}</li>
        ))}
      </ol>
    )
  }

  return output
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const jsonTruncate = (obj: unknown) => {
  return truncate(JSON.stringify(obj, null, 2))
}

export const timeTag = (dateTime?: string) => {
  let output: string | React.JSX.Element = ''

  if (dateTime) {
    output = (
      <time dateTime={dateTime} title={dateTime}>
        {new Date(dateTime).toUTCString()}
      </time>
    )
  }

  return output
}

export const checkboxInputTag = (checked: boolean) => {
  return <input type="checkbox" checked={checked} disabled />
}

/**
 * Formatss a datetime for display.
 */
export const formatDatetime = (
  datetime: string | Dayjs | Date,
  fallback?: string
): string => {
  const dt = dayjs(datetime)
  if (fallback && !dt.isValid()) {
    return fallback
  }
  return dt.format('ddd, MMM D, YYYY h:mm A')
}

/**
 * Formatss a datetime for a datetime-local form field.
 */
export const formatDatetimeLocalValue = (
  datetime: string | Dayjs | Date
): string | null => {
  const dt = dayjs(datetime)
  if (!dt.isValid()) {
    console.warn(`Invalid time recieved: ${datetime}`)
    return null
  }
  return dt.format('YYYY-MM-DDTHH:mm')
}

/**
 * Formats a datetime for GraphQL input.
 */
export const formatGqlDatetime = (
  datetime: string | Dayjs | Date
): string | null => {
  const dt = dayjs(datetime)
  if (!dt.isValid()) {
    console.warn(`Invalid time recieved: ${datetime}`)
    return null
  }
  return dt.toISOString()
}
