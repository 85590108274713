import React from 'react'

import HeadingLevel from '../Heading/Heading'

interface CardProps {
  title?: string
  subtitle?: string
  hideSeparator?: boolean
  children?: React.ReactNode
}

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  children,
  hideSeparator,
}) => {
  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <div className="flex flex-col">
        {title && (
          <HeadingLevel level={1} className="text-lg font-medium">
            {title}
          </HeadingLevel>
        )}
        {subtitle && <HeadingLevel level={2}>{subtitle}</HeadingLevel>}
        {!hideSeparator && (
          <div className="mt-[20px] h-[2px] w-full bg-gray-200" />
        )}
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  )
}

export default Card
