import { useEffect, useState } from 'react'

import { GetGenres, GetPbsGenres, GetVibes } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import { GET_GENRES } from 'src/common/queries'
import Button from 'src/components/Custom/Button/Button'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import TextInputModal from 'src/components/Custom/Modal/TextInputModal'
import Search from 'src/components/Custom/Search/Search'
import {
  DELETE_GENRE_VIBE,
  GET_PBS_GENRES,
  GET_VIBES,
} from 'src/pages/HeroLineupPage/queries'

import { creatorHeader, genreVibeHeader } from './constants'

const renderer = {
  shows: (itemData) =>
    itemData.shows !== 0 ? (
      <a className="text-indigo-700 underline" href={'#1'}>
        {itemData.shows}
      </a>
    ) : (
      itemData.shows
    ),
  localOverrides: (itemData) =>
    itemData.shows !== 0 ? (
      <a className="text-indigo-700 underline" href={'#1'}>
        {itemData.localOverrides}
      </a>
    ) : (
      itemData.shows
    ),
  displayName: (itemData) =>
    itemData.displayName ? itemData.displayName : '-',
}

interface MetadataListingTablesProps {
  isGenres?: boolean
  isVibes?: boolean
  isCreator?: boolean
}

const MetadataListingTables: React.FC<MetadataListingTablesProps> = ({
  isGenres = false,
  isVibes = false,
  isCreator = false,
}) => {
  const { currentUser } = useAuth()
  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = useState('')

  const {
    data: genresData,
    error: genresError,
    loading: loadingGenres,
    refetch: refetchGenres,
  } = useQuery<GetGenres>(GET_GENRES)
  const [genres, setGenres] = useState([])

  const { data: pbsGenresData } = useQuery<GetPbsGenres>(GET_PBS_GENRES)

  const {
    data: vibesData,
    error: vibesError,
    loading: loadingVibes,
    refetch: refetchVibes,
  } = useQuery<GetVibes>(GET_VIBES)
  const [vibes, setVibes] = useState([])

  const [deleteGenreVibe, { loading: loadingDeleteGenreVibe }] = useMutation(
    DELETE_GENRE_VIBE,
    {
      onCompleted: () => {
        toast.success(
          `${isGenres ? 'Genre' : isVibes ? 'Vibe' : 'Tag'} deleted`
        )
        if (isGenres) {
          refetchGenres()
        } else if (isVibes) {
          refetchVibes()
        }
        return true
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  useEffect(() => {
    if (vibesData) {
      setVibes(vibesData.genreVibes)
    }
    if (genresData || pbsGenresData) {
      setGenres([
        ...(genresData?.genreVibes ?? []),
        ...(pbsGenresData?.genreVibes ?? []),
      ])
    }
  }, [vibesData, genresData, pbsGenresData])

  const handleSearch = async (query: string) => {
    if (isGenres) {
      const allGenres = [
        ...(genresData?.genreVibes ?? []),
        ...(pbsGenresData?.genreVibes ?? []),
      ]
      setGenres(
        allGenres.filter((genre) =>
          genre.name.toLowerCase().includes(query.toLowerCase())
        )
      )
    } else if (isVibes) {
      setVibes(
        vibesData.genreVibes.filter((vibe) =>
          vibe.name.toLowerCase().includes(query.toLowerCase())
        )
      )
    }
    return []
  }

  const onSearchInputChange = (word: string): void => {
    handleSearch(word)
  }

  const onDropdownOptionsChange = () => {}

  const onClickEditItem = (itemData) => {
    const nextRoute =
      itemData.type == 'VIBE' ? routes.editVibe : routes.editGenre
    navigate(nextRoute({ id: itemData.id }))
  }

  const onClickDeleteItem = (itemData) => {
    setDeleteItem(itemData)
  }

  const actionsList = (itemData) => {
    return (
      <div className="flex min-h-12 flex-row justify-center space-x-2 p-2">
        {itemData.type !== 'PBS_GENRE' && (
          <Button
            title="Edit"
            onClick={() => onClickEditItem(itemData)}
            backgroundColor="bg-blue-600"
          />
        )}

        {currentUser.roles.includes('ADMIN') && (
          <Button
            title="Delete"
            onClick={() => onClickDeleteItem(itemData)}
            backgroundColor="bg-red-600"
            isLoading={loadingDeleteGenreVibe}
          />
        )}
      </div>
    )
  }

  const onClickAddNewTag = () => {
    navigate(
      isGenres ? routes.newGenre() : isVibes ? routes.newVibe() : routes.home()
    )
  }

  const getListingData = () => {
    let list = []
    if (isGenres && !loadingGenres && !genresError) {
      list = genres
    } else if (isVibes && !loadingVibes && !vibesError) {
      list = vibes
    }
    list = list.toSorted((a, b) => {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    return list
  }

  const modalButtonList = (deleteModal) => [
    {
      label: 'Continue',
      id: 'continue',
      type: deleteConfirmation === 'DELETE' ? 'primary' : 'disabled',
      onClick: () => {
        if (deleteConfirmation === 'DELETE') {
          deleteModal(null)
          setDeleteConfirmation('')
          deleteGenreVibe({ variables: { id: deleteItem.id } })
        }
      },
    },
    {
      label: 'Cancel',
      id: 'cancel',
      type: 'secondary',
      onClick: () => {
        deleteModal(null)
        setDeleteConfirmation('')
      },
    },
  ]

  return (
    <div className="w-full p-4">
      <HeadingLevel level={1}>
        {isGenres ? 'Genres' : isVibes ? 'Vibes' : isCreator ? 'People' : ''}
      </HeadingLevel>
      <div className="mt-5">
        {(isGenres || isVibes || isCreator) && (
          <Button
            title={
              isCreator
                ? 'Add New Person'
                : isGenres
                  ? 'Add New Genre'
                  : isVibes
                    ? 'Add New Vibe'
                    : ''
            }
            onClick={() => onClickAddNewTag()}
            backgroundColor="bg-blue-600"
            customButtonClass="mb-2"
          />
        )}
        <div className="my-4">
          <Search
            header={
              'Search for a ' +
              (isCreator ? 'Person' : isGenres || isVibes ? 'Tag' : '')
            }
            onSearch={handleSearch}
            onSearchInputChange={onSearchInputChange}
            dropdownOptions={[]}
            onDropdownOptionsChange={onDropdownOptionsChange}
          />
        </div>
        <ContentListingTable
          contentList={getListingData()}
          tableHeading={isCreator ? creatorHeader : genreVibeHeader}
          handleSort={() => {}}
          actionsList={actionsList}
          renderer={renderer}
        />
        <TextInputModal
          modalText="Type DELETE in the box below and click continue"
          buttonList={modalButtonList(setDeleteItem)}
          showModal={deleteItem !== null}
          setShowModal={setDeleteItem}
          textValue={deleteConfirmation}
          setTextValue={(text) => setDeleteConfirmation(text)}
        />
      </div>
    </div>
  )
}

export default MetadataListingTables

const MetadataGenresPage = () => {
  return (
    <>
      <Metadata title="Genres" />
      <MetadataListingTables isGenres />
    </>
  )
}

const MetadataVibesPage = () => {
  return (
    <>
      <Metadata title="Vibes" />
      <MetadataListingTables isVibes />
    </>
  )
}

const MetadataCreatorsPage = () => {
  return (
    <>
      <Metadata title="Creators" />
      <MetadataListingTables isCreator />
    </>
  )
}

export { MetadataCreatorsPage, MetadataGenresPage, MetadataVibesPage }
