import React from 'react'

import Button from '../Button/Button'

import CustomModal from './CustomModal'

export type PrimaryModalButtonListButton = {
  id: string
  label: string
  type?: 'primary' | 'secondary' | 'danger' | 'success'
  onClick: () => void
}

interface Props {
  buttonList?: Array<PrimaryModalButtonListButton>
  modalText: string | React.JSX.Element
  modalWidth?: string
  showModal: boolean
  setShowModal: (showModal: boolean) => void
}

const PrimaryModal: React.FC<Props> = ({
  buttonList = [],
  modalText,
  modalWidth,
  showModal,
  setShowModal,
}) => {
  // const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <CustomModal
      setShowModal={setShowModal}
      showModal={showModal}
      modalWidth={modalWidth}
    >
      <div className="text-center sm:text-left">
        <span>{modalText}</span>
        <div className="mt-5 grid grid-cols-2 gap-3">
          {buttonList?.length &&
            buttonList.map((button) => (
              <Button
                key={button.id}
                title={button.label}
                // TODO: Make this more flexible -- do not define specific types.
                customButtonClass={`${
                  button.type === 'primary'
                    ? 'bg-blue-700 hover-bg-blue-800'
                    : button.type === 'secondary'
                      ? 'bg-gray-700 hover:bg-gray-800'
                      : button.type === 'success'
                        ? 'bg-green-700 hover:bg-green-800'
                        : button.type === 'danger'
                          ? 'bg-red-700 hover:bg-red-800'
                          : 'bg-red-700 hover:bg-red-800'
                } w-full rounded-sm px-6 py-4 font-bold text-white hover:text-white`}
                onClick={button.onClick}
              />
            ))}
        </div>
      </div>
    </CustomModal>
  )
}

export default PrimaryModal
