import { useEffect, useState } from 'react'

import { IoImageOutline as ImageIcon } from 'react-icons/io5'
import { RiCloseCircleFill as CloseIcon } from 'react-icons/ri'

import { transformImage } from 'src/lib/images'

import Button from '../Button/Button'

interface DragAndDropImageAreaProps {
  errorClassName?: string
  helpText?: string
  id: string // identifier
  onFileSelection?: (files: File[]) => void
  onUpload?: (files: File[]) => void // handle save
  previewAlt?: string
  previewClassName?: string
  previewHeight?: number
  previewWidth?: number
  selectedFiles?: File[] | { image: string; profile: string }[]
  showPreview?: boolean
  showUploadButton?: boolean
  title?: string // works as a label on top of the component
}

export const getImageSource = (files) => {
  if (files[0] instanceof File) {
    return URL.createObjectURL(files[0])
  }
  return files?.[0]?.image
}

const DragAndDropImageArea = ({
  errorClassName,
  helpText,
  id,
  onFileSelection,
  onUpload,
  previewAlt,
  previewClassName,
  previewHeight,
  previewWidth,
  selectedFiles = [],
  showPreview = true,
  showUploadButton = true,
  title,
}: DragAndDropImageAreaProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [files, setFiles] = useState<
    File[] | { image: string; profile: string }[]
  >([])
  const [highlighted, setHighlighted] = useState(false)

  useEffect(() => {
    if (onFileSelection) {
      setFiles(selectedFiles)
    }
  }, [onFileSelection, selectedFiles])

  const handleDragEnter = (e) => {
    e.preventDefault()
    setHighlighted(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setHighlighted(false)
  }

  const validateAndSetFiles = (files: FileList) => {
    if (files.length > 1) {
      setErrorMessage('Only one file can be uploaded at a time')
      return
    }

    if (files.length === 1) {
      const file = files[0]

      if (file.size > 10485760) {
        setErrorMessage('File is too large')
        return
      }

      if (
        !file.type.match('image/png') &&
        !file.type.match('image/jpeg') &&
        !file.type.match('image/gif')
      ) {
        setErrorMessage('Only PNG, JPG, and GIF files are allowed')
        return
      }

      setErrorMessage('')
      setFiles([file])
      if (onFileSelection) {
        onFileSelection([file])
      }
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setHighlighted(false)

    const files = e.dataTransfer.files
    validateAndSetFiles(files)
  }

  const handleFileSelect = (e) => {
    e.preventDefault()
    setHighlighted(false)

    const files = e.target.files
    validateAndSetFiles(files)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleSave = () => {
    if (onUpload && files.length > 0) {
      onUpload(files as File[])
    }

    if (onFileSelection) {
      onFileSelection([])
    }

    setFiles([])
  }

  return (
    <div className="flex flex-col">
      {title && (
        <span className={`mt-4 text-sm ${errorClassName && 'text-red-600'}`}>
          {title}
        </span>
      )}
      <input
        type="file"
        className="hidden"
        onChange={handleFileSelect}
        accept="image/png, image/jpeg, image/gif"
        id={id}
      />
      {showPreview && files.length ? (
        <div className="relative">
          <label htmlFor={id}>
            <img
              src={transformImage({
                imageUrl: getImageSource(files),
                options: { height: previewHeight, width: previewWidth },
              })}
              alt={previewAlt || title}
              height={previewHeight}
              width={previewWidth}
              className={previewClassName || 'mx-auto rounded-lg object-cover'}
            />
          </label>
          <button
            type="button"
            onClick={() => {
              setFiles([])
              if (onFileSelection) {
                onFileSelection(null)
              }
            }}
            className="absolute -right-2 -top-2 rounded-xl bg-white"
          >
            <CloseIcon size="20px" />
          </button>
        </div>
      ) : (
        <div
          className={`mt-4 flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-8 text-center ${
            highlighted && 'border-blue-400 bg-blue-100'
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <ImageIcon size={48} className="text-gray-400" />

          <label htmlFor={id} className="cursor-pointer text-blue-700">
            Select a file{' '}
            <span className="text-gray-600">or drag and drop</span>
          </label>
          <span className="text-sm text-gray-500">
            PNG, JPG, GIF up to 10MB
          </span>
          <div className="mt-5 flex flex-col items-center justify-center">
            {files.map((file, index) => (
              <span key={index}>{file.name}</span>
            ))}
          </div>
          <span className="text-base text-red-600">{errorMessage}</span>
        </div>
      )}
      {showUploadButton && files.length > 0 && (
        <div className="mt-5 text-center">
          <Button
            customButtonClass="border-indigo-700 text-indigo-700 hover:text-white hover:bg-indigo-700"
            title={`Upload file${files.length > 1 ? 's' : ''}`}
            onClick={handleSave}
          />
        </div>
      )}
      {helpText && (
        <small className="mt-2 block text-gray-500">{helpText}</small>
      )}
    </div>
  )
}

export default DragAndDropImageArea
