import { TableHeadingType } from 'src/common/types'

export const newShowsHeader: Array<TableHeadingType> = [
  {
    id: 'image',
    field: 'image',
    label: 'Image',
  },
  {
    id: 'title',
    field: 'title',
    label: 'Title',
  },
  {
    id: 'createdat',
    field: 'createdAt',
    label: 'Date added',
    type: 'date',
  },
  {
    id: 'action',
    field: 'actions',
    label: 'Action',
  },
]

export const missingMetadataHeader: Array<TableHeadingType> = [
  {
    id: 'image',
    field: 'image',
    label: 'Image',
  },
  {
    id: 'title',
    field: 'title',
    label: 'Title',
  },
  {
    id: 'empty-fields',
    field: 'emptyFields',
    label: 'Empty fields',
  },
  {
    id: 'createdat',
    field: 'earliestFullLengthContentAvailableAt',
    label: 'Earliest content availability',
    type: 'date',
  },
  {
    id: 'action',
    field: 'actions',
    label: 'Action',
  },
]

export const genreVibeHeader: Array<TableHeadingType> = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
  },
  {
    id: 'slug',
    field: 'slug',
    label: 'Slug',
  },
  {
    id: 'action',
    field: 'actions',
    label: 'Action',
  },
]

export const creatorHeader: Array<TableHeadingType> = [
  {
    id: 'person',
    field: 'person',
    label: 'Person',
    canSort: true,
  },
  {
    id: 'display-name',
    field: 'displayName',
    label: 'Display name',
    type: 'renderer',
  },
  {
    id: 'action',
    field: 'actions',
    label: 'Action',
  },
]
