import { PiWarningBold } from 'react-icons/pi'

import Button from '../Button/Button'
import TextInput from '../TextInput/TextInput'

import CustomModal from './CustomModal'

interface Props {
  buttonList?: Array<{
    id: string
    label: string
    type?: string
    onClick: () => void
  }>
  modalText: string
  otherDescription?: string
  textValue: string
  setTextValue: (textValue: string) => void
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  placeholder?: string
  type?: string
}

const TextInputModal: React.FC<Props> = ({
  buttonList = [],
  modalText,
  otherDescription = '',
  textValue = '',
  setTextValue,
  showModal,
  setShowModal,
  placeholder = 'Write your thoughts here...',
  type = '',
}) => {
  return (
    <div className="flex items-center justify-center">
      <CustomModal
        setShowModal={setShowModal}
        showModal={showModal}
        modalWidth="max-w-xl"
      >
        <div className="text-center sm:text-left">
          <span className="text-xl font-medium">{modalText}</span>
          {type === 'textInput' ? (
            <div className="mt-3">
              <TextInput
                type="text"
                id="textinputModal"
                name="title"
                value={textValue}
                onChange={(value) => setTextValue(value)}
                placeholder={placeholder}
              />
            </div>
          ) : (
            <textarea
              rows={3}
              className="mt-5 block w-full rounded-lg border bg-white p-2.5 text-sm text-gray-900"
              placeholder={placeholder}
              value={textValue}
              defaultValue={textValue}
              onChange={(e) => {
                if (setTextValue && e?.target?.value) {
                  setTextValue(e.target.value)
                }
              }}
            />
          )}
          <div className="mt-5 flex items-center gap-6">
            {buttonList?.length &&
              buttonList.map((button) => (
                <Button
                  key={button.id}
                  title={button.label}
                  customButtonClass={`${
                    button.type === 'disabled'
                      ? 'bg-zinc-500'
                      : button.type === 'primary'
                        ? 'bg-blue-600'
                        : 'bg-red-600'
                  } rounded-sm px-6 py-4 text-base font-bold text-white hover:text-white`}
                  onClick={button.onClick}
                />
              ))}
          </div>
          {otherDescription && (
            <div className="mt-5 flex items-center gap-3">
              <PiWarningBold className="h-6 w-6 min-w-fit" />
              <span className="text-md">{otherDescription}</span>
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  )
}

export default TextInputModal
