import { Image } from 'cloudflare/resources/images/v1/v1'

import { FieldValues, UseFormSetValue } from '@redwoodjs/forms'

import FallbackImage16x9 from '../assets/fallback-images/16x9.png'
import FallbackImage2x3 from '../assets/fallback-images/2x3.png'

export type TransformImageOptions = {
  // Any CSS color code for transparent image background fill (Cloudflare only).
  background?: string
  // Percentage to blur the image.
  blur?: number
  format?: string
  height?: number
  // Image quality percentage (1 - 100).
  quality?: number
  width?: number
}

export type ImageWithProfile = {
  image: string
  profile: string
}

/**
 * Get an image from an array of images and profiles.
 */
export const getImage = (
  images: Array<{ image: string; profile: string }> = [],
  profile: string = ImageProfile.PBS_SHOW_POSTER,
  useFallback = true,
  transformOptions?: TransformImageOptions
): ImageWithProfile | null => {
  const getFallbackImage = () => {
    if (profile.includes('2x3')) {
      return { image: FallbackImage2x3, profile }
    }
    if (profile.includes('16x9')) {
      return { image: FallbackImage16x9, profile }
    }
    return null
  }
  let image = images?.findLast((i) => i.profile == profile)

  // For PBS mezzanine images, try to fall back on kids profiles
  // if the regular profiles as not found. For normalized results
  // this is handled in the `images` resolver, but the web side
  // looks at `PbsShow.images` and `PbsVideo.images` directly to
  // profile configuration forms for admin users.
  if (!image) {
    if (profile == ImageProfile.PBS_SHOW_MEZZANINE) {
      image = images?.findLast(
        (i) => i.profile == ImageProfile.PBS_SHOW_MEZZANINE_KIDS
      )
      if (image) {
        image = { ...image, profile: ImageProfile.PBS_SHOW_MEZZANINE }
      }
    } else if (profile == ImageProfile.PBS_VIDEO_MEZZANINE) {
      image = images?.findLast(
        (i) => i.profile == ImageProfile.PBS_VIDEO_MEZZANINE_KIDS
      )
      if (image) {
        image = { ...image, profile: ImageProfile.PBS_VIDEO_MEZZANINE }
      }
    }
  }

  if (image && transformOptions) {
    image = {
      ...image,
      image: transformImage({
        imageUrl: image.image,
        options: transformOptions,
      }),
    }
  }
  return image ? image : useFallback ? getFallbackImage() : null
}

/**
 * Transforms images with support for Cloudflare and PBS ITS image hosting.
 *
 * Transoform options key are mapped directly to Cloudflare transform URL parameters.
 * PBS ITS keys are converted where supported.
 *
 * @see api/src/lib/images
 * @see https://developers.cloudflare.com/images/transform-images/transform-via-url/
 * @see https://docs.pbs.org/space/ITS/4423682/Image+Transformation+Service+(ITS)
 */
export const transformImage = ({
  imageUrl,
  options,
}: {
  imageUrl: string
  options: TransformImageOptions
}) => {
  if (imageUrl.startsWith(process.env.CLOUDFLARE_IMAGES_URL)) {
    // Remove any trailing slashes from the URL to prevent creating invalid URLs
    // with multiple slashes.
    imageUrl = imageUrl.replace(/\/$/, '')
    const cloudflareOptions = []
    for (const [key, value] of Object.entries(options)) {
      if (value) {
        cloudflareOptions.push(`${key}=${encodeURIComponent(value)}`)
      }
    }
    imageUrl += `/${cloudflareOptions.join(',')}`
  } else if (imageUrl.startsWith('https://image.pbs.org/')) {
    const { blur, format, height, quality, width } = options
    const imageUrlWithTransforms = new URL(imageUrl)
    if (blur) {
      imageUrlWithTransforms.searchParams.append('blur', String(blur))
    }
    if (format) {
      imageUrlWithTransforms.searchParams.append('format', format)
    }
    if (height || width) {
      imageUrlWithTransforms.searchParams.append(
        'resize',
        `${width ? width : ''}x${height ? height : ''}`
      )
    }
    if (quality) {
      imageUrlWithTransforms.searchParams.append('quality', String(quality))
    }
    imageUrl = imageUrlWithTransforms.toString()
  }
  return imageUrl
}

export type UploadImagesAndUpdateFormImage = {
  key: string
  file: File
  uploadUrl: string
}

export const uploadImagesAndUpdateForm = async (
  images: Array<UploadImagesAndUpdateFormImage>,
  setValue: UseFormSetValue<FieldValues>,
  isVideo = false
) => {
  const uploadImage = async (image: UploadImagesAndUpdateFormImage) => {
    const { key, file, uploadUrl } = image
    const imageUrl = await uploadImageToCloudflare(uploadUrl, file)
    const profile = isVideo
      ? ImageProfile.PBS_VIDEO_MEZZANINE
      : key === 'customMezzanineImage'
        ? ImageProfile.PBS_SHOW_MEZZANINE
        : key === 'customBackgroundImage'
          ? ImageProfile.PBS_SHOW_BACKGROUND
          : ImageProfile.PBS_SHOW_POSTER
    setValue(key, { image: imageUrl, profile })
  }

  const uploads = images.map(async (image) => {
    await uploadImage(image)
  })

  return Promise.all(uploads)
}

/**
 * Mutation for creating an image upload URL used by uploadImage.
 *
 * @see uploadImage
 */
export const CREATE_IMAGE_UPLOAD_URL_MUTATION = gql`
  mutation CreateImageUploadUrl {
    createImageUploadUrl {
      id
      uploadUrl
    }
  }
`

/**
 * Uploads an image to Cloudflare and returns the base image URL.
 *
 * This URL will not work on it's own. A variant or transdform must be appended.
 *
 * @see https://developers.cloudflare.com/images/transform-images/transform-via-url/#options
 */
export const uploadImageToCloudflare = async (
  imageUploadUrl: string,
  file: File
): Promise<string> => {
  const body = new FormData()
  body.append('file', file)
  const uploadResult = await fetch(imageUploadUrl, { method: 'post', body })
  const response = (await uploadResult.json()) as { result: Image }
  return `${process.env.CLOUDFLARE_IMAGES_URL}/${response.result.id}`
}

/**
 * Image profiles.
 *
 * @see api/src/lib/image
 */
export enum ImageProfile {
  HERO_MEZZANINE = 'hero-mezzanine',
  HERO_MOBILE = 'hero-mobile',
  PBS_SHOW_BACKGROUND = 'background',
  PBS_SHOW_MEZZANINE = 'show-mezzanine16x9',
  PBS_SHOW_MEZZANINE_KIDS = 'show-kids-16x9',
  PBS_SHOW_POSTER = 'show-poster2x3',
  PBS_VIDEO_MEZZANINE = 'asset-mezzanine-16x9',
  PBS_VIDEO_MEZZANINE_KIDS = 'asset-kids-mezzanine1-16x9',
  STATION_BACKGROUND = 'station-background',
  STATION_COLOR_LOGO = 'station-color-logo',
  STATION_LOGO_PRIMARY = 'station-logo-primary',
  STATION_LOGO_SECONDARY = 'station-logo-secondary',
  STATION_PASSPORT_LOGO = 'station-passport-logo',
  STATION_WHITE_LOGO = 'station-white-logo',
}
