export const GET_LSI_MEMBER_STATIONS = gql`
  query GetLsiMemberStations {
    stations(isLsiMember: true) {
      id
      callSign
      colorPrimary
      colorSecondary
      colorBackground
      borderStation
      smallColorLogo: image(profile: "station-color-logo") {
        image(transforms: { width: 300 })
      }
      isLsiMember
    }
  }
`
