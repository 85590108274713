/**
 * Station URL profiles.
 */
export enum StationUrlProfiles {
  CONTACT = 'contact',
  DONATE = 'donate',
  DONATE_URL_ANDROID = 'donate-url-android',
  DONATE_URL_FIRETV = 'donate-url-firetv',
  DONATE_URL_GOOGLETV = 'donate-url-googletv',
  DONATE_URL_IOS = 'donate-url-ios',
  DONATE_URL_LGTV = 'donate-url-lgtv',
  DONATE_URL_ROKU = 'donate-url-roku',
  DONATE_URL_SAMSUNGTV = 'donate-url-samsungtv',
  DONATE_URL_TVOS = 'donate-url-tvos',
  DONATE_URL_WEB = 'donate-url-web',
  FACEBOOK = 'facebook',
  HELP = 'help',
  INSTAGRAM = 'instagram',
  MASTODON = 'mastodon',
  PASSPORT = 'passport',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}
