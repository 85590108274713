import CardSection from 'src/components/Custom/Card/CardSection'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'

import { StationInputType, UrlsType } from '../../../common/types'
export interface ContactDetailsData {
  email: string
  telephone: string
  fax: string
  addressStreet: string
  addressCity: string
  addressState: string
  addressZip: string
  facebook: string | null
  twitter: string | null
  instagram: string | null
  tiktok: string | null
  youtube: string | null
  mastodon: string | null
  urls: UrlsType[]
}

interface ContactDetailsProps {
  data: ContactDetailsData
  onChange: (data: ContactDetailsData) => void
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
}

const ContactDetails = ({
  data,
  onChange,
  updateStation,
  loadingUpdateStation,
}: ContactDetailsProps) => {
  const updateData = (value: string, key: string) => {
    const _data = { ...data }
    _data[key] = value
    onChange(_data)
  }

  const prepareUrls = (profileInput: string) => {
    const updatedUrls = [...data.urls]

    const urlIndex = updatedUrls.findIndex(
      (url) => url.profile === profileInput
    )

    if (urlIndex !== -1) {
      updatedUrls[urlIndex] = { profile: profileInput, url: data[profileInput] }
    } else {
      updatedUrls.push({ profile: profileInput, url: data[profileInput] })
    }

    return updatedUrls
  }

  const emailValidationRules = {
    required: 'Please enter a valid email address.',
    validate: {
      email: (value) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

        if (!value) {
          return 'Please enter a valid email address.'
        }

        if (typeof value !== 'string' || !emailPattern.test(value)) {
          return 'Please enter a valid email address.'
        }

        return true
      },
    },
  }
  const phoneNumberPattern = /^\d{10}$/
  const phoneNumberValidationRules = {
    required: 'Please enter a valid phone number, including area code.',
    validate: {
      phoneNumber: (value) => {
        if (!value) {
          return 'Please enter a valid phone number, including area code.'
        }

        if (!phoneNumberPattern.test(value)) {
          return 'Please enter a valid phone number, including area code.'
        }

        return true
      },
    },
  }

  const faxNumberValidationRules = {
    validate: {
      faxNumber: (value) => {
        if (value === '') {
          return true
        }

        if (!phoneNumberPattern.test(value)) {
          return 'Please enter a valid phone number, including area code.'
        }

        return true
      },
    },
  }

  const urlValidationRules = {
    validate: {
      url: (value) => {
        const urlPattern = /^http/

        if (value === '') {
          return true
        }

        if (!urlPattern.test(value)) {
          return 'Please enter a valid URL'
        }

        return true
      },
    },
  }

  return (
    <div className="w-full">
      <CardSection title="Address">
        <UpdatableText
          addressValue={{
            addressStreet: data.addressStreet,
            addressCity: data.addressCity,
            addressState: data.addressState,
            addressZip: data.addressZip,
          }}
          onChange={(value, key) => updateData(value, key)}
          onSave={() =>
            updateStation({
              addressStreet: data.addressStreet,
              addressCity: data.addressCity,
              addressState: data.addressState,
              addressZip: data.addressZip,
            })
          }
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Email">
        <UpdatableText
          value={data.email}
          onChange={(v) => updateData(v, 'email')}
          onSave={() => updateStation({ email: data.email })}
          validationRules={emailValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Facebook">
        <UpdatableText
          value={data.facebook}
          onChange={(v) => updateData(v, 'facebook')}
          onSave={() => {
            const updatedUrls = prepareUrls('facebook')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Fax">
        <UpdatableText
          value={data.fax}
          onChange={(v) => updateData(v, 'fax')}
          onSave={() => updateStation({ fax: data.fax })}
          validationRules={faxNumberValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Instagram">
        <UpdatableText
          value={data.instagram}
          onChange={(v) => updateData(v, 'instagram')}
          onSave={() => {
            const updatedUrls = prepareUrls('instagram')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Mastodon">
        <UpdatableText
          value={data.mastodon}
          onChange={(v) => updateData(v, 'mastodon')}
          onSave={() => {
            const updatedUrls = prepareUrls('mastodon')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Telephone">
        <UpdatableText
          value={data.telephone}
          onChange={(v) => updateData(v, 'telephone')}
          onSave={() => updateStation({ phone: data.telephone })}
          validationRules={phoneNumberValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="TikTok">
        <UpdatableText
          value={data.tiktok}
          onChange={(v) => updateData(v, 'tiktok')}
          onSave={() => {
            const updatedUrls = prepareUrls('tiktok')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="YouTube">
        <UpdatableText
          value={data.youtube}
          onChange={(v) => updateData(v, 'youtube')}
          onSave={() => {
            const updatedUrls = prepareUrls('youtube')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="X (formerly known as Twitter)">
        <UpdatableText
          value={data.twitter}
          onChange={(v) => updateData(v, 'twitter')}
          onSave={() => {
            const updatedUrls = prepareUrls('twitter')

            return updateStation({
              urls: updatedUrls,
            })
          }}
          validationRules={urlValidationRules}
          loadingData={loadingUpdateStation}
        />
      </CardSection>
    </div>
  )
}

export default ContactDetails
