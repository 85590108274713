import React from 'react'

interface StatusPillProps {
  title: string
  type: string
}

const statusType = {
  success: {
    backgroundColor: 'bg-green-100',
    textColor: 'text-green-800',
  },
  error: {
    backgroundColor: 'bg-red-100',
    textColor: 'text-red-800',
  },
  warning: {
    backgroundColor: 'bg-yellow-100',
    textColor: 'text-yellow-800',
  },
  info: {
    backgroundColor: 'bg-purple-100',
    textColor: 'text-purple-800',
  },
  gray: {
    backgroundColor: 'bg-gray-100',
    textColor: 'text-gray-800',
  },
  pink: {
    backgroundColor: 'bg-red-100',
    textColor: 'text-red-800',
  },
  indigo: {
    backgroundColor: 'bg-indigo-100',
    textColor: 'text-indigo-800',
  },
}

const StatusPill = ({ title, type }: StatusPillProps) => {
  const { backgroundColor, textColor } = statusType[type]

  return (
    <span
      className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium leading-4 ${backgroundColor} ${textColor}`}
      role="status"
      aria-label={title}
    >
      {title}
    </span>
  )
}

export default StatusPill
