import { useEffect, useState } from 'react'

import { ImageUploadUrl } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { StationInputType, UrlsType } from 'src/common/types'
import CardSection from 'src/components/Custom/Card/CardSection'
import DragAndDropImageArea from 'src/components/Custom/DragAndDropImageArea/DragAndDropImageArea'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import {
  CREATE_IMAGE_UPLOAD_URL_MUTATION,
  transformImage,
  ImageProfile,
  uploadImageToCloudflare,
} from 'src/lib/images'

export interface AboutData {
  name: string
  about: string
  images: UrlsType[]
}

interface AboutProps {
  data: AboutData
  onChange?: (data: { about?: string; images?: UrlsType[] }) => void
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
}

const About = ({
  data,
  onChange,
  updateStation,
  loadingUpdateStation,
}: AboutProps) => {
  const [backgroundImage, setBackgroundImage] = useState<UrlsType | null>()

  const updateData = (value: string, key: string) => {
    const _data = { ...data }
    _data[key] = value
    onChange(_data)
  }

  const [createImageUploadUrl] = useMutation<{
    createImageUploadUrl: ImageUploadUrl
  }>(CREATE_IMAGE_UPLOAD_URL_MUTATION)

  const handleImageUpload = async (files: File[]) => {
    const updatedImages = [...data.images]

    const mutationResult = await createImageUploadUrl()
    const { uploadUrl } = mutationResult.data.createImageUploadUrl

    await toast
      .promise(uploadImageToCloudflare(uploadUrl, files[0]), {
        loading: 'Uploading background image...',
        success: 'Image uploaded successfully',
        error: 'Error uploading image',
      })
      .then((newImageUrl) => {
        const profileToUpdate = ImageProfile.STATION_BACKGROUND

        const imageIndex = updatedImages.findIndex(
          (image) => image.profile === profileToUpdate
        )

        if (imageIndex !== -1) {
          updatedImages[imageIndex] = {
            profile: profileToUpdate,
            url: newImageUrl,
          }
        } else {
          updatedImages.push({ profile: profileToUpdate, url: newImageUrl })
        }

        updateStation({ images: updatedImages }).then(() => {
          onChange({ images: updatedImages })
        })
      })
  }

  const aboutTextValidationRules = {
    required: `Please provide a description of your station's streaming app.`,
    validate: {
      aboutText: (value) => {
        if (!value || value.trim() === '') {
          return `Please provide a description of your station's streaming app.`
        }

        return true
      },
    },
  }

  const findProfile = (
    array: { profile: string; url: string }[],
    profileValue: string
  ) => {
    return array?.find((obj) => obj.profile === profileValue) ?? null
  }

  useEffect(() => {
    setBackgroundImage(findProfile(data.images, 'station-background'))
  }, [data.images])

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center">
        <CardSection title="About text">
          <UpdatableText
            value={data.about}
            textArea
            onChange={(v) => updateData(v, 'about')}
            onSave={() => updateStation({ about: data.about })}
            validationRules={aboutTextValidationRules}
            loadingData={loadingUpdateStation}
          />
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Background Photo">
          {backgroundImage ? (
            <img
              className="mb-2 max-h-[140px] w-full rounded-lg object-cover"
              alt="background"
              src={
                backgroundImage?.url
                  ? transformImage({
                      imageUrl: backgroundImage.url,
                      options: { width: 1200 },
                    })
                  : undefined
              }
            />
          ) : (
            <em>Not set</em>
          )}
          <DragAndDropImageArea
            id="aboutPage"
            title="Change photo"
            onUpload={handleImageUpload}
          />
        </CardSection>
      </div>
    </div>
  )
}

export default About
