export const GET_GENRE_VIBES = gql`
  query GetGenreVibes($type: GenreVibeType) {
    genreVibes(type: $type) {
      id
      name
      slug
      type
    }
  }
`

export const GET_GENRES = gql`
  query GetGenres {
    genreVibes(type: GENRE) {
      id
      slug
      name
      type
    }
  }
`

export const GET_NORMALIZED_SHOW = gql`
  query GetNormalizedShow(
    $id: String!
    $stationId: UUID
    $country: CountryCode
  ) {
    normalizedShow(id: $id, stationId: $stationId, country: $country) {
      contentRating
      createdAt
      descriptionShort
      descriptionLong
      genreVibes {
        id
        name
        type
      }
      genre {
        id
        name
        type
      }
      images {
        image
        profile
      }
      isAvailable
      isNotAvailableReason
      language
      passportLevel
      productionLocale
      runtime
      seasons {
        seasonNumber
        episodeCount
      }
      showType
      slug
      title
      updatedAt
      videoTotals {
        total
        fullLength
        PUBLIC {
          total
          fullLength
          CLIP
          EPISODE
          PREVIEW
          SPECIAL
        }
        ALL_MEMBERS {
          total
          fullLength
          CLIP
          EPISODE
          PREVIEW
          SPECIAL
        }
        STATION_MEMBERS {
          total
          fullLength
          CLIP
          EPISODE
          PREVIEW
          SPECIAL
        }
      }
    }
  }
`

export const GET_NORMALIZED_VIDEO = gql`
  query GetNormalizedVideo(
    $id: String!
    $stationId: UUID
    $country: CountryCode
  ) {
    normalizedVideo(id: $id, stationId: $stationId, country: $country) {
      availabilities {
        ALL_MEMBERS {
          end
          isAvailable
          isNotAvailableReason
          start
        }
        PUBLIC {
          end
          isAvailable
          isNotAvailableReason
          start
        }
        STATION_MEMBERS {
          end
          isAvailable
          isNotAvailableReason
          start
        }
      }
      createdAt
      duration
      funderMessage
      id
      isAvailable
      isNotAvailableReason
      normalizedShow(stationId: $stationId, country: $country) {
        id
        title
      }
      skipSponsorship
      slug
      updatedAt
    }
  }
`

export const GET_STATION_BRANDING = gql`
  query GetStationBranding($callSign: String!) {
    station(callSign: $callSign) {
      name
      callSign
      colorPrimary
      colorSecondary
      colorBackground
      images
      urls
      isLsiMember
      phone
      email
    }
  }
`

export const SEARCH_SHOWS = gql`
  query SearchShows(
    $search: String!
    $stationId: UUID
    $filters: FilterShowInput
  ) {
    searchShows(search: $search, stationId: $stationId, filters: $filters) {
      createdAt
      descriptionLong
      genreVibes {
        id
        name
        type
      }
      hasSubtitles
      id
      images {
        image
        profile
      }
      productionLocale
      showType
      title
      updatedAt
      videoTotals {
        total
      }
    }
  }
`

export const SEARCH_VIDEOS = gql`
  query SearchVideos(
    $search: String!
    $stationId: UUID
    $filters: FilterVideoInput
  ) {
    searchVideos(search: $search, stationId: $stationId, filters: $filters) {
      availabilities {
        ALL_MEMBERS {
          end
          isAvailable
          start
        }
        PUBLIC {
          end
          isAvailable
          start
        }
        STATION_MEMBERS {
          end
          isAvailable
          start
        }
      }
      contentRating
      episodeNumber
      id
      images {
        image
        profile
      }
      isAvailable
      normalizedShow(stationId: $stationId) {
        title
      }
      season
      title
      updatedAt
      videoType
    }
  }
`
