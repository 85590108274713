import * as Sentry from '@sentry/react'

Sentry.init({
  beforeSend(event) {
    // Remove PII from user.
    if (event.user) {
      delete event.user.email
    }
    return event
  },
  dsn: process.env.SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.25,
})
Sentry.setTag('appSide', 'web')

export default Sentry
