import React from 'react'

interface LinksProps {
  links: Array<{
    id: string | number
    label: string
    href?: string
  }>
  linkType?: 'primary' | 'secondary'
  linkStyle?: string
  selectedTabs?: Array<string | number>
  onClickLink?: null | ((id: string | number) => void)
  bgColor?: string
}

const Links: React.FC<LinksProps> = ({
  links = [],
  linkType = 'primary',
  linkStyle = '',
  selectedTabs = [],
  onClickLink = null,
  bgColor = 'bg-indigo-600',
}) => {
  return (
    <div className="w-full">
      <div className="flex space-x-4">
        {links.map(({ href = '', label, id }, index) => {
          if (href) {
            return (
              <div
                key={id || index}
                className={`cursor-pointer px-4 py-2 text-[#003ECC] underline decoration-1 ${linkStyle}`}
              >
                <a href={href}>{label}</a>
              </div>
            )
          }
          return (
            <button
              key={id || index}
              className={`cursor-pointer px-4 py-2 ${linkStyle} ${
                selectedTabs.includes(id) && linkType === 'primary'
                  ? bgColor + ' rounded-md text-white'
                  : 'text-[#4f46e5]'
              }`}
              onClick={() => onClickLink(id)}
            >
              {label}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Links
