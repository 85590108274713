import React from 'react'

import Loader from '../Loader/Loader'

interface ButtonProps {
  backgroundColor?: string
  customButtonClass?: string
  icon?: React.JSX.Element
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: () => void
  outlineColor?: string
  style?: React.CSSProperties
  title: string
  type?: 'button' | 'submit' | 'reset'
}

const Button = ({
  backgroundColor,
  customButtonClass = '',
  icon,
  isDisabled = false,
  isLoading,
  onClick,
  outlineColor,
  style,
  title,
  type = 'button',
}: ButtonProps) => {
  let buttonClasses = `${
    customButtonClass ? customButtonClass : ''
  } py-2 px-4 rounded-lg font-medium text-sm inline-flex items-center h-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 justify-center relative overflow-hidden`

  if (!backgroundColor) {
    buttonClasses += ' border'
    if (outlineColor) {
      buttonClasses += ` ${outlineColor}`
    } else {
      buttonClasses +=
        ' border-gray-300 text-gray-600 hover:border-gray-500 hover:text-gray-900'
    }
  } else {
    buttonClasses += ` ${backgroundColor} text-white`
  }

  return (
    <button
      aria-label={title}
      className={buttonClasses}
      onClick={onClick}
      style={style}
      type={type}
      {...(isDisabled === true || isLoading
        ? { disabled: true }
        : { disabled: false })}
    >
      <div
        className={`flex items-center ${isLoading ? 'invisible' : 'visible'}`}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {title}
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      )}
    </button>
  )
}

export default Button
