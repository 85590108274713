import { Fragment } from 'react'

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { FaAngleDown, FaAngleUp, FaCheck } from 'react-icons/fa'

export type MultiSelectOption = {
  id: number
  label: string
  value: string
}

type MultiSelectDropdownProps = {
  options: MultiSelectOption[]
  selectedOptions: MultiSelectOption[]
  onSelectedOptionsChange: (options: MultiSelectOption[]) => void
  title?: string
}

const MultiSelectDropdown = ({
  options,
  selectedOptions,
  onSelectedOptionsChange = () => {},
  title,
}: MultiSelectDropdownProps) => {
  const toggleOption = (
    option: MultiSelectOption,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    const isSelected = selectedOptions.some(
      (selected) => selected.id === option.id
    )
    onSelectedOptionsChange(
      isSelected
        ? selectedOptions.filter((selected) => selected.id !== option.id)
        : [...selectedOptions, option]
    )
  }

  return (
    <Listbox as="div" className="space-y-1" value={selectedOptions} multiple>
      {({ open }) => (
        <>
          <Label className="block text-sm font-medium text-gray-700">
            {title ? title : 'Select an option'}
          </Label>
          <div className="relative">
            <ListboxButton className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
              {selectedOptions.length > 0 ? (
                selectedOptions.map((option) => option.label).join(', ')
              ) : (
                <span className="text-gray-400">Select options</span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                {open ? <FaAngleDown /> : <FaAngleUp />}
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                static
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {options.map((option) => (
                  <ListboxOption
                    key={option.id}
                    value={option}
                    as={Fragment}
                    disabled={false}
                  >
                    {({ focus }) => (
                      <button
                        className={`${
                          focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        } relative w-full cursor-pointer select-none py-2 pl-3 pr-9 text-left`}
                        onClick={(e) => toggleOption(option, e)}
                        aria-pressed={
                          selectedOptions.some(
                            (selected) => selected.id === option.id
                          )
                            ? true
                            : false
                        }
                      >
                        <span
                          className={`${
                            selectedOptions.some(
                              (selected) => selected.id === option.id
                            )
                              ? 'font-semibold'
                              : 'font-normal'
                          } block truncate`}
                        >
                          {option.label}
                        </span>
                        {selectedOptions.some(
                          (selected) => selected.id === option.id
                        ) && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                            <FaCheck />
                          </span>
                        )}
                      </button>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default MultiSelectDropdown
