import React, { useState, useEffect } from 'react'

import DatePicker, {
  DateRangeType,
  DatepickerType,
} from 'react-tailwindcss-datepicker'
import './DatePicker.css'

interface DatePickerProps extends DatepickerType {
  containerClass?: string
  toggleClass?: string
  inputClass?: string
  isRequired?: boolean
}
const DatePickerComponent: React.FC<DatePickerProps> = ({
  onChange,
  value,
  containerClass = '',
  toggleClass = '',
  inputClass = '',
  isRequired = false,
  ...componentProps
}) => {
  const [dateValue, setDateValue] = useState<DateRangeType | undefined>(value)
  const [uniqueId] = useState<string>(Math.random().toString(36).substring(7))

  // useEffect to sync local state with the value prop
  useEffect(() => {
    setDateValue(value)
  }, [value])

  const handleValueChange = (newValue: DateRangeType) => {
    setDateValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    const buttons = document.querySelectorAll('.date-picker-button')
    const inputs = document.querySelectorAll(`.s${uniqueId}`)
    const disabledButtons = document.querySelectorAll('button.text-gray-400')

    buttons.forEach((button) => {
      button.setAttribute('aria-label', 'Open date picker')
    })

    inputs.forEach((input) => {
      input.setAttribute('role', 'textbox')
      return isRequired && input.setAttribute('required', 'true')
    })

    disabledButtons.forEach((button) => {
      button.setAttribute('disabled', 'true')
    })
  }, [isRequired, uniqueId])

  return (
    <div
      className="relative"
      role="group"
      aria-label="Date picker"
      aria-describedby={`date-picker-description-${uniqueId}`} // Use the unique ID
    >
      <DatePicker
        containerClassName={`date-picker rounded-md ${containerClass}`}
        toggleClassName={`absolute left-2 top-3 date-picker-button text-gray-500 ${toggleClass}`}
        inputClassName={`date-picker-input pl-8 py-2 w-full rounded-md border border-gray-300 ${inputClass} s${uniqueId}`}
        value={dateValue}
        onChange={handleValueChange}
        {...componentProps}
      />
      <span id={`date-picker-description-${uniqueId}`} className="sr-only">
        Select a date using the calendar.
      </span>
    </div>
  )
}

export default DatePickerComponent
